import React from "react";
import {Menu, MenuItem} from "@material-ui/core";
import {Button} from "react-admin";
import UserIcon from '@material-ui/icons/AccountCircle';

interface Props {

}

const RoleChange: React.FC<Props> = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItemClicked = (role: 'admin' | 'editor' | 'redactor') => {
        localStorage.setItem('permissions', role);
        handleClose();
    };

    return(
        <>
            <Button label={'Choose permission role'} onClick={handleClick}>
                <UserIcon/>
            </Button>
            <Menu keepMounted
                  id="fade-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  style={{marginLeft: '1.5rem', marginTop: '2.2rem'}}
            >
                <MenuItem onClick={() => menuItemClicked('admin')}>Admin</MenuItem>
                <MenuItem onClick={() => menuItemClicked('editor')}>Editor</MenuItem>
                <MenuItem onClick={() => menuItemClicked('redactor')}>Redactor</MenuItem>
            </Menu>
        </>
    )
};

export default RoleChange;
