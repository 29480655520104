import UserList from './List';
import UserShow from './Show';
import UserCreate from './Create';
import UserEdit from './Edit';
import UserIcon from '@material-ui/icons/AccountCircle';

export type Model =
{
    Name: string,
};

export default {
    name: "User",
    icon: UserIcon,
    list: UserList,
    show: UserShow,
    create: UserCreate,
    edit: UserEdit,
};
