import { stringify } from 'query-string';
import { fetchJson, headers } from './ServiceUtils';
import { ResourceType, ResourceModel, getResourceBase } from '../models';
import Config from '../Config';

const URL = () => Config.api.url + Config.api.data;

const getResourcePath = (resource: ResourceType) =>
{
    const resourceBase = getResourceBase(resource);
    return resourceBase
        ? `${resourceBase}/CVR.${resource}`
        : `${resource}`;
}

export const List = async <T extends ResourceType>(resource: T, query: any)
    : Promise<{ "odata.count": string, value: ResourceModel<T>[] }> =>
{
    const res = getResourcePath(resource);
    return fetchJson(`${URL()}/${res}?${stringify(query)}`, {
        method: 'GET',
        headers,
    });
}

export const Get = async <T extends ResourceType>(resource: T, id: string, { expand }: Partial<{ expand: string[] }> = { })
    : Promise<ResourceModel<T>> =>
{
    const res = getResourcePath(resource);
    const query = expand ? `?$expand=${expand.join(",")}` : "";
    return fetchJson(`${URL()}/${res}(${id})${query}`, {
        method: 'GET',
        headers,
    });
}

export const Create = async <T extends ResourceType>(resource: T, data: any)
    : Promise<ResourceModel<T>> =>
{
    const resourceBase = getResourceBase(resource);
    const res = resourceBase || resource;
    return fetchJson(`${URL()}/${res}`, {
        method: 'POST',
        body: JSON.stringify({ "odata.type": `CVR.${resource}`, ...data }),
        headers,
    });
}

export const Update = async <T extends ResourceType>(resource: T, id: string, data: any)
    : Promise<void> =>
{
    const resourceBase = getResourceBase(resource);
    const res = resourceBase || resource;
    return fetchJson(`${URL()}/${res}(${id})`, {
        method: "PUT",
        body: JSON.stringify({ "odata.type": `CVR.${resource}`, ...data }),
        headers,
    });
}

export const Delete = async <T extends ResourceType>(resource: T, id: string)
    : Promise<void> =>
{
    const res = getResourcePath(resource);
    return fetchJson(`${URL()}/${res}(${id})`, {
        method: "DELETE",
        headers,
    });
}

export const CreateRelation = async <T extends ResourceType, U extends ResourceType>
    (resource: T, id: string, relation: string, relationResource: U, relationId: string) =>
{
    const res = getResourceBase(resource) || resource;
    return fetch(`${URL()}/${res}(${id})/$links/${relation}`, {
        method: "POST",
        body: JSON.stringify({ "url": `${relationResource}(${relationId})` }),
        headers,
    })
}


export const DeleteRelation = async <T extends ResourceType>(resource: T, id: string, relation: string, relationId: string)
    : Promise<void> =>
{
    const res = getResourceBase(resource) || resource;
    return fetchJson(`${URL()}/${res}(${id})/$links/${relation}(${relationId})`, {
        method: "DELETE",
        headers,
    });
}

export const GetEntityAttachments = async (resource: ResourceType, id: string) =>
{
    return fetchJson(`${URL()}/GetEntityAttachments?entitySet='${resource}'&entityId=${id}`, {
        method: "GET",
        headers,
    });
}

export const DeleteFile = async (id: string) =>
{
    return fetchJson(`${URL()}/DeleteFile?fileId=${id}`, {
        method: "POST",
        headers,
    });
}

export const ConvertLeadToClient = async (id: number) =>
{
    return fetchJson(`${URL()}/ConvertLeadToClient?leadId=${id}`, {
        method: "POST",
        headers,
    });
}

export const GetMyPermissions = async () =>
{
    return fetchJson(`${URL()}/GetMyPermissions`, {
        method: "GET",
        headers,
    });
}

export const GetClientByCodeOrName = async (code: string, name: string)
    : Promise<{ "odata.count": string, value: ResourceModel<"Client">[] }> => {
    return fetchJson(`${URL()}/Lead/CVR.Client?$top=1&$filter=Code eq '${code}' or Name eq '${name}'`, {
        method: "GET",
        headers,
    });
}
