import React from 'react';
import { LoginForm } from 'react-admin';
import { Paper } from "@material-ui/core";

export default () => {

    return <>
        <img src="full.png" alt={'Cessco Logo'} style={{ display: "block", margin: "4rem auto 4rem auto" }} />
        <Paper style={{ padding: "1rem", maxWidth: "24rem", margin: "auto" }}>
            <LoginForm />
        </Paper>
    </>
};