import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import AddressField from '../../components/AddressField';

export default (props: any) => {

    return(
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="Id"/>
                <TextField source="Name"/>
                <TextField source="VATNo"/>
                <AddressField source="Address"/>
            </SimpleShowLayout>
        </Show>
    )
};