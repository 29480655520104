import React from 'react';
import { List, Datagrid, TextField, DateField, Filter, NumberInput, TextInput } from 'react-admin';

const ClientFilter = (props: any) => <Filter {...props}>
    <NumberInput source="Id" alwaysOn />
    <TextInput source="Name" alwaysOn />
    <TextInput source="Code" />
    <TextInput source="VATNo" alwaysOn />
</Filter>;

export default (props: any) => <List {...props} filters={<ClientFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <TextField source="Name" />
        <TextField source="Code" />
        <DateField source="DateFrom" />
    </Datagrid>
</List>;
