import React from 'react';
import { TextField, Show, SimpleShowLayout } from 'react-admin';

export default (props: any) => <Show {...props}>
    <SimpleShowLayout>
        <TextField source="Id" />
        <TextField source="Name" />
        <TextField source="Position" />
        <TextField source="Email" />
        <TextField source="Phone" />
        <TextField source="Mobile" />
    </SimpleShowLayout>
</Show>;
