export default
{
    name: "CessTrec",
    version: "0.0.1",
    background: {
        main: "paper.jpg",
        login: "paper.jpg", //"https://cessco.eu/wp-content/uploads/2018/03/ucb_banner.jpg",
    },
    api:
    {
        //url:    "https://localhost:44300/",
        url:    "api/",
        login:  "LoginService.svc",
        data:   "DataService.svc",
        file:   "FileService.svc",
        util:   "UtilService.svc",
    },
    viesApi: "https://ec.europa.eu/taxation_customs/vies/checkVatService.wsdl",
}