import CountryIcon from '@material-ui/icons/Language';
import CountryList from './List';
import { IdType } from '..';

export type Model =
{
    Name: string,
    Code: string,
    DefaultCurrencyId?: IdType,
};

export default
{
    name: "Country",
    icon: CountryIcon,
    list: CountryList,
};