import { fetchJson, headers } from './ServiceUtils';
import Config from '../Config';

const URL = () => Config.api.url + Config.api.login;

export const Login = async (username: string, password: string): Promise<string> =>
{
    return fetchJson(`${URL()}/Login`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers,
    });
}

export const Logout = async() : Promise<void> =>
{
    return fetchJson(`${URL()}/Logout`, {
        method: "POST",
        headers,
    });
}

export const LoginCheck = async (): Promise<string> =>
{
    return fetchJson(`${URL()}/LoginCheck`, {
        method: "GET",
        headers,
    });
}

export const GetVersion = async () =>
{
    return fetchJson(`${URL()}/GetVersion`, {
        method: "GET",
        headers,
    });
}

export const ChangePassword = async () =>
{
    return fetchJson(`${URL()}/ChangePassword`, {
        method: "POST",
        headers,
    });
}

export const ResetPassword = async () =>
{
    return fetchJson(`${URL()}/ResetPassword`, {
        method: "POST",
        headers,
    });
}

export const SetNewPassword = async () =>
{
    return fetchJson(`${URL()}/SetNewPassword`, {
        method: "POST",
        headers,
    });
}