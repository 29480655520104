import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    background: {
        //backgroundImage: `url('${Config.background.main}')`,
        //backgroundSize: 'cover'
    },
});

export default (props: any) =>
{
    const classes = useStyles();
    return <Layout {...props} className={classes.background} appBar={AppBar} />;
}