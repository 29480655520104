import React from "react";
import { Button, useTranslate, useNotify, useRedirect } from "react-admin";
import { Dialog, DialogContent, DialogActions, Stepper, Step, StepLabel, CircularProgress } from "@material-ui/core";
import { Publish as UploadIcon, Close as CloseIcon, NavigateNext as NextIcon } from '@material-ui/icons';
import { Form } from "react-final-form";

import { StepUploadFile, uploadFileNextStep, UploadFileResult } from "./StepUploadFile";
import { StepApproveData, approveDataNextStep } from "./StepApproveData";
import { StepDone } from "./StepDone";
import {ApproveDataResult} from "./StepApproveData";

type Step = "upload" | "approve" | "done";

export default () => {

    const [ open, setOpen ] = React.useState(false);
    const [ loading, setLoading ] = React.useState(false);
    const [ file, setFile ] = React.useState<File>();
    const [ uploadResult, setUploadResult ] = React.useState<UploadFileResult>();
    const [ activeStep, setActiveStep ] = React.useState(0);
    const [ saveResult, setSaveResult] = React.useState<ApproveDataResult>();

    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    
    const isStep = React.useCallback((step: Step) => {
        return (step === "upload" && activeStep === 0) ||
            (step === "approve" && activeStep === 1) ||
            (step === "done" && activeStep === 2);
    }, [activeStep]);

    const handleClose = React.useCallback(() => {
        setLoading(false);
        setFile(undefined);
        // @ts-ignore
        setUploadResult({}); // Cannot be set to undefined
        setActiveStep(0);
        setOpen(false);
    }, []);

    const handleNext = React.useCallback(async ({ invoiceFile, invoices }: UploadFileResult) =>
    {
        setLoading(true);

        if (isStep("upload")) {
            if (!file) {
                notify("No file selected.", "warning");
                return;
            }
            await uploadFileNextStep(file)
                .then((result: any) => {
                    setUploadResult(result);
                    setActiveStep(current => current + 1);
                }).catch((err:any) =>
                    notify(err, "warning")
                );
        } else if (isStep("approve")) {
            await approveDataNextStep(invoiceFile, invoices)
                .then(result => {
                    if (result.invoiceFileResult?.invoiceFile?.Id) {
                        redirect('show', '/invoiceFile', result.invoiceFileResult?.invoiceFile?.Id);
                        notify(translate('cvr.message.invoiceFileCreated', {Code: result.invoiceFileResult?.invoiceFile.Code}));
                        handleClose()
                    } else {
                        setSaveResult(result);
                        setActiveStep(current => current + 1);
                    }
                }).catch(err =>
                    notify(err, "warning")
                );
        } else if (isStep("done")) {
            handleClose();
        }
        setLoading(false);
    }, [file, handleClose, isStep, notify, redirect, translate]);

    return <>
        <Button label="cvr.action.uploadInvoiceFile" onClick={() => setOpen(true)}>
            <UploadIcon />
        </Button>
        <Form onSubmit={handleNext} initialValues={uploadResult} >
        { (props: any) =>
            <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose} scroll="body" style={{zIndex: 2}}>
                <DialogContent>
                    <Stepper activeStep={activeStep}>
                        <Step><StepLabel>{translate("cvr.action.selectZipFile")}</StepLabel></Step>
                        <Step><StepLabel>{translate("cvr.action.approveZipFile")}</StepLabel></Step>
                        <Step><StepLabel>{translate("cvr.action.zipFileDone")}</StepLabel></Step>
                    </Stepper>
                { 
                    isStep("upload") ? <StepUploadFile setFile={setFile} /> :
                    isStep("approve") ? <StepApproveData /> :
                    isStep("done") && <StepDone saveResult={saveResult}/>
                }
                </DialogContent>
                <DialogActions>
                    {activeStep < 2 && <Button onClick={handleClose} label="ra.action.cancel">
                        <CloseIcon />
                    </Button>}
                    <Button onClick={props.handleSubmit}
                        disabled={loading || (isStep("upload") && !file) || (isStep("approve") && !!uploadResult?.errors.length)}
                        label={["cvr.action.processFile", "ra.action.save", "ra.action.close"][activeStep]}>
                    { loading
                        ? <CircularProgress size={20} />
                        : <NextIcon />
                    }
                    </Button>
                </DialogActions>
            </Dialog>
        }
        </Form>
    </>;
}
