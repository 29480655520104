import React from "react";
import { SelectInput, useDataProvider } from 'react-admin';
import { useField } from 'react-final-form';

interface Props {
    source: string
    relatedSource: string
    optionText?: (c: any) => string
    label?: string
    validate?: () => {}
}

const CurrencyInput: React.FC<Props> = ({source, relatedSource, optionText, validate, label}) => {

    const [data, setData] = React.useState();
    const [currencies, setCurrencies] = React.useState();
    const [choices, setChoices] = React.useState([]);

    const field = useField(relatedSource);

    const dataProvider = useDataProvider();

    React.useEffect(() => {
        //Get Country_Currency List
        dataProvider.getList('Country_Currency', {filter: {},
            pagination: {page: 1, perPage: 300},
            sort: {field: 'id', order: 'ASC'}})
            .then((res:any) => setData(res.data))
            .catch((err: any) => console.log(err));
        //Get Currency List
        dataProvider.getList('Currency', {filter: {},
            pagination: {page: 1, perPage: 300},
            sort: {field: 'id', order: 'ASC'}})
            .then((res:any) => setCurrencies(res.data))
            .catch((err: any) => console.log(err))
    }, [dataProvider]);

    const value = field.input.value;

    React.useEffect(() => {
        if (data !== undefined && currencies !== undefined) {
            if (value) {
                // @ts-ignore
                let newCurrencies = data.map((obj) =>
                {
                    return(obj.id === value ? obj.name: null)
                }
                ).filter((obj: any) => obj !== null);
                if (!newCurrencies.includes('EUR')) {
                    newCurrencies.push('EUR');
                }
                // @ts-ignore
                let newChoices = newCurrencies.map((curr: string) => currencies.filter(obj => obj.id === curr)[0]);
                setChoices(newChoices)
            }
        }
    }, [data, value, currencies]);

    return(
        <>
            <SelectInput
                source={source}
                optionText={optionText}
                validate={validate}
                label={label}
                //@ts-ignore
                choices={choices?.length ? choices: currencies?.length ? currencies: []}
            />
        </>
        )
};

export default CurrencyInput;
