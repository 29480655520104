import EnumIcon from "@material-ui/icons/List";
import EnumList from "./List";

export type Model =
{
    Name: string,
};

export default (name: string) =>
({
    name: name,
    icon: EnumIcon,
    list: EnumList,
});