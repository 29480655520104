import React, {useState} from "react";

import {
    useRefresh,
    useNotify,
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    useTranslate,
} from "react-admin";

import { Add, Close } from '@material-ui/icons';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";


import { CreateRelation } from "../services/DataService";

import {ResourceType} from "../models";

interface Props {
    record: any,
    resource: ResourceType
}

const AddContactPopup: React.FC<Props> = ({record, resource}) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    const [open, setOpen] = useState(false);

    const onRowClick = (contactId: any) => {
        const companyId = record.Id;
        if (contactId) {
            try {
                CreateRelation(resource, companyId, 'Contacts', 'Contact', contactId)
                    .then((response) => {
                        refresh();
                        if (response.ok) {
                            notify('cvr.message.contactAdded', 'success')
                        } else {
                            notify('cvr.message.contactNotAdded' + response.statusText, 'error')
                        }
                    })
            } catch (err) {
                notify('cvr.message.contactNotAdded' + err.message, 'error')
            }
        }
    };

    return (
        <>
            <Button color={"primary"} onClick={() => setOpen(true)}><Add />{translate('ra.action.add')}</Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"xl"}>
                <DialogTitle>
                    {translate('ra.action.add')} {' '} {translate('resources.Contact.name', 1)}
                </DialogTitle>
                <DialogContent>
                    <List resource={'Contact'}
                          hasCreate={false}
                          hasEdit={false}
                          hasShow={false}
                          hasList={false}
                          filters={<ContactFilter/>}
                          exporter={false}
                          basePath={'/Contact'}
                          filter={record.Contacts? {Id: {ne: record.Contacts.map((obj: any) => obj.Id)}}:{}}
                    >
                        <Datagrid rowClick={(id:any) => {onRowClick(id)}} isRowSelectable={() => false}>
                            <TextField source={'Name'}/>
                            <TextField source={'Position'}/>
                            <TextField source={'Email'}/>
                            <TextField source={'Phone'}/>
                        </Datagrid>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={() => setOpen(false)}><Close/>{translate('ra.action.close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default AddContactPopup;

const ContactFilter = (props: any) => <Filter {...props}>
    <TextInput source="Name" alwaysOn />
    <TextInput source="Position" alwaysOn />
    <TextInput source="Email" alwaysOn />
    <TextInput source="Phone" alwaysOn />
</Filter>;
