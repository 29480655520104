import { fetchJson, headers } from './ServiceUtils';
import Config from '../Config';
import { ResourceModel } from "../models";

const URL = () => Config.api.url + Config.api.util;

export type VIESResult =
{
    Valid: boolean,
    Name?: string,
    Address?: string,
    Suggestions?: ResourceModel<"Supplier">[],
}

export const GetVIES = async (countryCode: string, vatNumber: string): Promise<VIESResult> =>
{
    return fetchJson(`${URL()}/GetVIES?countryCode=${countryCode}&vatNumber=${vatNumber}`, {
        method: "GET",
        headers,
    });
}