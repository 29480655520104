import { usePermissions } from "react-admin";

export type CVRPermissions = {
    Role: boolean,
    User: boolean,
    AuditLog: boolean,
    AttachmentRead: boolean,
    AttachmentWrite: boolean,
    Admin: boolean,
    Debug: boolean,
};

export const createPermissions = (perms: string[]): CVRPermissions => {
    return {
        User: perms.includes("User"),
        Role: perms.includes("Role"),
        AuditLog: perms.includes("AuditLog"),
        AttachmentRead: perms.includes("AttachmentRead"),
        AttachmentWrite: perms.includes("AttachmentWrite"),
        Admin: perms.includes("Admin"),
        Debug: perms.includes("Debug"),
    };
};

export const useCVRPermissions = (): CVRPermissions | undefined => {
    const { permissions } = usePermissions();
    return permissions;
};