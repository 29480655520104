import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput } from 'react-admin';

const CountryFilter = (props: any) => <Filter {...props}>
    <TextInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
</Filter>;


export default (props: any) => <List {...props} filters={<CountryFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="id" label="Currency" reference="Country_Currency" link={() => false}>
            <TextField source="name" />
        </ReferenceField>
    </Datagrid>
</List>;
