import React from 'react';
import { Admin, Resource } from 'react-admin';
import { DataProvider, AuthProvider, I18nProvider } from './providers';
//import { DummyAuthProvider, DummyDataProvider } from './providers/DummyProviders';
import Layout from './theming/Layout';
import Dashboard from './theming/Dashboard';
import Login from './theming/Login';
import Theme from './theming/Theme';
import { ThemingProvider, useTheming } from "./theming/ThemingProvider";
import Lead from './models/lead';
import Client from './models/client';
import User from './models/user';
import Contact from './models/contact';
import Country from './models/country';
import Address from './models/address';
import Currency from './models/currency';
import CountryCurrency from './models/country_currency';
import Invoice from './models/invoice';
import InvoiceFile from './models/invoice_file';
import Supplier from './models/supplier';
import Enum from './models/enum';
import { CVRPermissions } from './models/Permissions';

const can = (permission: boolean, action: "list" | "show" | "edit" | "create") => permission 
    ? { } 
    : { [action]: undefined };

const canList = (permission: boolean) => can(permission, "list");
//const canCreate = (permission: boolean) => can(permission, "create");
//const canShow = (permission: boolean) => can(permission, "show");
//const canEdit = (permission: boolean) => can(permission, "edit");

const ProvideredApp = () =>
{
    const { theme } = useTheming();

    return <Admin
        theme={Theme(theme)}
        layout={Layout}
        loginPage={Login}
        dashboard={Dashboard}
        dataProvider={DataProvider}
        authProvider={AuthProvider}
        i18nProvider={I18nProvider}
    >
        { (permissions: CVRPermissions) => [
            <Resource {...Lead} />,
            <Resource {...Client} />,
            permissions.Admin && <Resource {...User} />,
            <Resource {...Contact} />,
            <Resource {...Address} />,
            <Resource {...InvoiceFile} />,
            <Resource {...Invoice} />,
            <Resource {...Supplier} />,
            <Resource {...Country} />,
            <Resource {...Currency} />,
            <Resource {...CountryCurrency} />,
            <Resource {...Enum("InvoiceFileType")} {...canList(permissions.Debug)} />,
            <Resource {...Enum("InvoiceFileState")} {...canList(permissions.Debug)} />,
        ] }
    </Admin>;
}

const App = () =>
{
    return <ThemingProvider>
        <ProvideredApp />
    </ThemingProvider>
};

export default App;
