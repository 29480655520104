import AddressIcon from "@material-ui/icons/LocationCity";
import AddressList from "./List";
import AddressShow from "./Show";
import AddressEdit from "./Edit";
import AddressCreate from "./Create";

export type AddressType =
{
    Name: string,
    Street: string,
    City: string,
    PostalCode: string,
    Country: string,
}

export type Model =
{
    AddressType: AddressType
};

export default
{
    name: "Address",
    icon: AddressIcon,
    list: AddressList,
    show: AddressShow,
    edit: AddressEdit,
    create: AddressCreate,
};