import React, {useEffect} from "react";

import {
    TextInput, ArrayInput, Button, ReferenceInput, SelectInput, DateInput, SimpleFormIterator,
    FormDataConsumer, AutocompleteInput
} from "react-admin";

import { useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RemoveRedEye as ShowIcon } from '@material-ui/icons';

import { required } from "../../utils/validators";
import { Create } from "../../services/DataService";
import { Upload } from "../../services/FileService";

import { Model as InvoiceFile } from "../../models/invoice_file";
import { InvoiceInfo } from ".";
import CurrencyInput from "../CurrencyInput";

export type ApproveDataResult = {
    invoiceFileResult: {invoiceFile: any, errors: any[]},
    invoicesResult: {invoice: any, errors: any[]}[],
};

const useStyles = makeStyles( () => ({
    list: {
        '& section': {
            display: 'grid',
            gridAutoFlow: 'column',
        }
    },
    blubIcon: {
        display: 'grid',
        width: '20px',
        height: 'calc(100% - 1rem)'
    },
}));

export const StepApproveData = () =>
{
    const form = useForm();

    const classes = useStyles();

    form.mutators = {...arrayMutators};

    const vatYear = form.getState().values.invoiceFile.VATYear;
    const invoices = form.getState().values.invoices;

    useEffect(() => {
        if (invoices.length) {
            let totalVat = 0;
            for (let i=0; i < invoices.length; i++) {
                totalVat = totalVat + invoices[i].VAT
            }
            form.change('invoiceFile.TotalVAT', totalVat)
        }
    }, [invoices, form]);

    useEffect(() => {
        if (vatYear !== undefined) {
            if (vatYear > 1900) {
                form.change('invoiceFile.ApplicationFrom', vatYear + '-01-01');
                form.change('invoiceFile.ApplicationTo', vatYear + '-12-31')
            }
        }
    }, [vatYear, form]);

    const { errors, warnings } = form.getState().values;

    return <>
        { !!errors.length && <Alert severity="error">
            { errors.map((err: string, index: number) => <div key={index}>{err}</div>) }
        </Alert> }
        { !!warnings.length && <Alert severity="warning">
            { warnings.map((warn: string, index: number) => <div key={index}>{warn}</div>) }
        </Alert> }
        <Grid container direction="row" alignContent="space-between">
            <Grid item xs={3}>
                <ReferenceInput reference={'Client'} label={'Client'} source={'invoiceFile.ClientId'} validate={required()}
                                filterToQuery={(searchText:any) => ({'Name': searchText})}>
                    <AutocompleteInput
                        optionText={(curr: any) => curr ? curr?.Name: ''}
                        optionValue={'Id'}/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
                <ReferenceInput source="invoiceFile.SubmitCountry" label={'Submit country'} reference="Country">
                    <AutocompleteInput
                        optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')': ''}
                        optionValue={'id'}/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
                <CurrencyInput source={'invoiceFile.SubmitCurrency'} relatedSource={'invoiceFile.SubmitCountry'}
                               validate={required()} optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')': ''} label={'Submit currency'}/>
            </Grid>
            <Grid item xs={3}>
                <ReferenceInput source="invoiceFile.InvoiceFileTypeId" label={'Type'} reference="InvoiceFileType" validate={required()} initialValue={1}>
                    <SelectInput optionText="Name" optionValue={'Id'}/>
                </ReferenceInput>
            </Grid>
        </Grid>
        <Grid container direction="row" alignContent="space-between">
            <Grid item xs={3}><TextInput source="invoiceFile.TotalVAT" label={'Total VAT'} validate={required()} /></Grid>
            <Grid item xs={3}><TextInput source="invoiceFile.VATYear" label={'VAT Year'} validate={required()} /></Grid>
            <Grid item xs={3}><DateInput source="invoiceFile.ApplicationFrom" label={'Application from'} validate={required()} /></Grid>
            <Grid item xs={3}><DateInput source="invoiceFile.ApplicationTo" label={'Application to'} validate={required()} /></Grid>
        </Grid>
        <ArrayInput source={'invoices'} className={classes.list}>
            <SimpleFormIterator disableRemove disableAdd >
                <TextInput source={'Name'} label={'Name'} validate={required()} />
                <TextInput source={'VAT'} label={'VAT'} validate={required()} />
                <FormDataConsumer>
                    {/*
                    // @ts-ignore */
                        ({ scopedFormData, getSource }) => {
                            //Prevents warning 'You did not call getSource inside FormDataConsumer' (which is called inside inputs or fields)
                            getSource();
                            return (
                                <div className={classes.blubIcon}>
                                    <ShowBlobButton record={scopedFormData}/>
                                </div>
                            );
                        }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    </>
};

const ShowBlobButton = (props: { record?: InvoiceInfo}) =>
{
    const handleClick = () => {
        window.open(URL.createObjectURL(props.record?.Blob));
    };

    return props.record?.Blob ? <Button onClick={handleClick}>
        <ShowIcon />
    </Button> : null;
};

export const approveDataNextStep = async (invoiceFile: Partial<InvoiceFile>, invoices: InvoiceInfo[]): Promise<ApproveDataResult> => {

    const missing = (field: string) => {
        throw new Error(`${field} missing`)
    };

    const invoiceFileData = {
        ClientId: invoiceFile.ClientId || missing("ClientId"),
        SubmitCountry: invoiceFile.SubmitCountry || missing("SubmitCountry"),
        SalesCountry: invoiceFile.SubmitCountry || missing("SubmitCountry"),
        SubmitCurrency: invoiceFile.SubmitCurrency || missing("SubmitCurrency"),
        SalesCurrency: invoiceFile.SubmitCurrency || missing("SubmitCurrency"),
        VATYear: invoiceFile.VATYear || missing("VATYear"),
        ApplicationFrom: invoiceFile.ApplicationFrom || missing("ApplicationFrom"),
        ApplicationTo: invoiceFile.ApplicationTo || missing("ApplicationTo"),
        InvoiceFileTypeId: invoiceFile.InvoiceFileTypeId || missing("InvoiceFileTypeId"),
    };

    let createdInvoiceFile: any;
    const invoiceFileErrors = [];

    try {
        createdInvoiceFile = await Create("InvoiceFile", invoiceFileData);
    } catch (err) {
        invoiceFileErrors.push(err)
    }

    const invoiceFileResult = {invoiceFile: createdInvoiceFile, errors: invoiceFileErrors};

    const processInvoice = async (info: InvoiceInfo) => {

        const invoiceData = {
            InvoiceFileId: createdInvoiceFile?.Id,
            SupplierName: info.SupplierName,
            SupplierVATNo: info.SupplierVATNo,
            SupplierAddress: info.SupplierAddress,
            InvoiceDate: info.InvoiceDate,
            AmountOfVAT: info.VAT,
        };

        let invoiceResult: any;
        const invoiceErrors = [];

        try {
             invoiceResult = await Create("Invoice", invoiceData);
        } catch (err) {
            invoiceErrors.push(err)
        }

        try {
            if (info.Blob) {
                 Create("File", {
                    EntitySet: "Invoice",
                    EntityId: invoiceResult?.Id,
                }).then(newFile => {
                    Upload(newFile?.Id, info.Name, info.Blob)
                })
            }
        } catch (err) {
            invoiceErrors.push(err)
        }

        return {invoice: invoiceResult, errors: invoiceErrors}
    };

    const invoicesResult = await Promise.all(invoices.map(processInvoice));

    return {invoiceFileResult, invoicesResult};
};
