import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export default (props: any) => <List {...props} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <TextField source="SupplierName" />
        <TextField source="NatureOfGoods" />
        <ReferenceField source="InvoiceFileId" reference="InvoiceFile">
            <TextField source="Code" />
        </ReferenceField>
    </Datagrid>
</List>;
