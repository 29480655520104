import { JsonResourceType } from "../models";
import fakeDataProvider from 'ra-data-fakerest';

const resourceFromJson = (resource: JsonResourceType, key: string, value: any) => {
    switch (resource) {
        case "Currency": return { id: key, name: value };
        case "Country": return { id: key, name: value };
        case "Country_Currency": return { id: key, name: value };
        default: throw new Error("Unknown JsonResourceType");
    }
};

const getJson = async (resource: JsonResourceType) =>
{
    return fetch(`json/${resource.toLowerCase()}.json`)
        .then(res => res.json())
}

const makeResource = (json: any, resource: JsonResourceType) =>
{
    return Object.entries(json)
        .map(([key, val]) => resourceFromJson(resource, key, val));
}

let provider: any = undefined;
export const getJsonProvider = async () => {
    if (provider) return provider;

    const [ currency, country, country_currency ] = await Promise.all([
        getJson("Currency"),
        getJson("Country"),
        getJson("Country_Currency"),
    ]);
    provider = fakeDataProvider({
        Currency: makeResource(currency, "Currency"), 
        Country: makeResource(country, "Country"),
        Country_Currency: makeResource(country_currency, "Country_Currency"),
    });
    return provider;
}
