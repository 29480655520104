import { Login, LoginCheck, Logout } from '../services/LoginService';
import { GetMyPermissions } from '../services/DataService';
import { CVRPermissions, createPermissions } from "../models/Permissions";

const login = async (params: { username: string, password: string }) =>
{
    const { username, password } = params;
    const userId = await Login(username, password);
    if (!userId) throw new Error('cvr.error.loginFailed');
    //getDataFromAPI('GET', DataAPI.GetRoles(data).url);
    localStorage.setItem('uid', userId);
    localStorage.setItem('permissions', 'admin')
}

const logout = async (params: any) =>
{
    await Logout();
    localStorage.removeItem('permissions');
	localStorage.removeItem('uid');
}

const checkAuth = async () =>
{
    const userId = await LoginCheck().catch(err => undefined);
    if (!userId) throw new Error("cvr.error.notLogged");
}

const getPermissions = async (): Promise<CVRPermissions> =>
{
    const perms: string[] = await GetMyPermissions()
        .then(perm => (perm.value || []).map((val: any) => val.Name));

    return createPermissions(perms);
}

const checkError = async (error: any) =>
{
    console.log("AuthProvider: error caught\n", error);
    const status = error.status || 0;
    switch (status)
    {
        case 0: throw new Error("Unknown error");
        case 400:
            return Promise.resolve();
        case 401: throw new Error('Zadané přihlašovací údaje nejsou správné');
        case 403: // Forbidden
            return Promise.resolve();
        default:
            return Promise.resolve();
    }
}

export default
{
    login,
    logout,
    checkAuth,
    checkError,
    getPermissions,
}
