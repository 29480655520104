import React from "react";
import { FileInput, FileField } from "react-admin";
import { required } from "../../utils/validators";
import JSZip from "jszip";
import { Model as InvoiceFileModel } from "../../models/invoice_file";
import { InvoiceInfo } from ".";
import { GetClientByCodeOrName } from "../../services/DataService";
import DataProvider from "../../providers/DataProvider";

type InvoiceFileInfo = {
    ClientCode: string,
    ClientName: string,
    SubmitCountry: string,
    SubmitCurrency: string,
    TotalVAT: number,
    VATYear: number,
    InvoiceCount: number,
    Invoices: InvoiceInfo[],
}

type ZipResult = {
    invoiceFile: InvoiceFileInfo | undefined,
    errors: string[],
    warnings: string[],
}

const parseCsv = async (csv: JSZip.JSZipObject): Promise<{ info: InvoiceFileInfo, errors: string[], warnings: string[] }> =>
{
    const errors: string[] = [];
    const warnings: string[] = [];
    const info: InvoiceFileInfo = {
        ClientCode: "",
        ClientName: "",
        SubmitCountry: "",
        SubmitCurrency: "",
        TotalVAT: 0,
        VATYear: 0,
        InvoiceCount: 0,
        Invoices: [],
    };

    const check = <T,_>(old: T, current: T): T => {
        if (!old) return current;
        else if (old !== current) errors.push(`Inconsistent data: ${old} --> ${current}`);
        return old;
    }

    const getYearFromPath = (path: string): number => {
        const parts = path.split("_");
        const year = (parts[parts.length - 2] || "").substr(0,4);
        return Number.parseInt(year) || 0;
    }

    const lines = await csv.async("text")
        .then(text => text.match(/[^\r\n]+/g)?.map(line => line.split(","))) || [];

    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        if (!line[0]) { // summary line
            const [ , , , total, currency, , count ] = line.map(token => token.trim());
            info.SubmitCurrency = check(currency, info.SubmitCurrency);
            if (info.Invoices.length !== parseInt(count)) {
                errors.push(`Invoice count doesn't match: ${info.Invoices.length} --> ${parseInt(count)}`);
            }
            info.TotalVAT = parseFloat(total);
            const sum = info.Invoices.reduce((prev, cur) => prev + cur.VAT, 0);
            if (info.TotalVAT !== sum) {
                errors.push(`Total VAT doesn't match: ${info.TotalVAT} --> ${sum}`);
            }
            break;
        } else { // standard line - Client ID, Client name, Submit Country, VAT, Currency, File path
            const [ code, name, country, vat, currency, file ] = line.map(token => token.trim());
            info.ClientCode = check(info.ClientCode, code);
            info.ClientName = check(info.ClientName, name);
            info.SubmitCountry = check(info.SubmitCountry, country);
            info.SubmitCurrency = check(info.SubmitCurrency, currency);
            info.VATYear = check(info.VATYear, getYearFromPath(file));
            info.Invoices.push({ VAT: parseFloat(vat), Name: file.split("\\").pop() || "" });
        }
    }
    
    return { info, errors, warnings };
}

const parseZip = async (zip: JSZip): Promise<ZipResult> =>
{
    let invoiceFile: InvoiceFileInfo | undefined = undefined;
    const errors: string[] = [];
    const warnings: string[] = [];

    const csvs = zip.filter(path => path.endsWith(".csv"));

    if (csvs.length === 0) errors.push("No *.csv file in archive.");
    else if (csvs.length > 1) errors.push("Multiple *.csv files in archive");

    if (csvs.length > 0) {
        const csv = await parseCsv(csvs[0]);
        invoiceFile = csv.info;
        errors.push(...csv.errors);
        warnings.push(...csv.warnings);

        const files = await Promise.all(zip
            .filter(path => !path.endsWith(".csv"))
            .map(async file => ({
                name: file.name,
                blob: await file.async("arraybuffer").then(data => new Blob([data], { type: 'application/pdf' })), // TODO check mime type corectness
            }))
        );

        invoiceFile.Invoices.forEach(invoice => {
            const file = files.find(f => f.name === invoice.Name);
            if (!file) warnings.push(`Cannot find file for ${invoice.Name}.`);
            else invoice.Blob = file.blob;
        });
    }

    return {
        invoiceFile,
        errors,
        warnings,
    }
}

const convertToFormData = async (info: InvoiceFileInfo): Promise<{ data: Partial<InvoiceFileModel>, errors: string[], warnings: string[] }> =>
{
    const errors: string[] = [];
    const warnings: string[] = [];

    const client = await GetClientByCodeOrName(info.ClientCode, info.ClientName);
    const clientId = client.value ? client.value[0]?.Id : undefined;
    if (!clientId) warnings.push(`Client with code '${info.ClientCode}' or name '${info.ClientName}' not found.`);

    const country = await DataProvider
        .getList("Country", { filter: { name: info.SubmitCountry }, sort: { field: "id", order: "ASC" }, pagination: { page: 1, perPage: 1 }})
        .then(res => res.data[0]);

    return {
        data: {
            SubmitCountry: country?.id,
            SubmitCurrency: info.SubmitCurrency,
            VATYear: info.VATYear,
            ClientId: clientId,
        },
        errors,
        warnings,
    }
}

export type UploadFileResult = {
    invoiceFile: Partial<InvoiceFileModel>,
    invoices: InvoiceInfo[],
    errors: string[],
    warnings: string[],
}

export const uploadFileNextStep = async (file: File): Promise<UploadFileResult> =>
{
    try {
        const zip = await JSZip.loadAsync(file);
        const result = await parseZip(zip);
        if (!result.invoiceFile) {
            return { 
                errors: result.errors, 
                warnings: result.warnings, 
                invoiceFile: {}, 
                invoices: [] 
            };
        } else {
            const invoiceFile = await convertToFormData(result.invoiceFile);
            return { 
                invoiceFile: invoiceFile.data, 
                invoices: result.invoiceFile.Invoices, 
                errors: result.errors.concat(invoiceFile.errors),
                warnings: result.warnings.concat(invoiceFile.warnings)
            };
        }
    } catch (err) {
        return Promise.reject(err.toString())
    }
}

export const StepUploadFile = ({ setFile }: { setFile: (file: File) => void }) =>
{
    return <FileInput source="file" accept={".zip"} validate={required()} onChange={setFile}>
        <FileField source="src" title="title" />
    </FileInput>;
}
