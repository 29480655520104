import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, NumberInput } from 'react-admin';

const LeadFilter = (props: any) => <Filter {...props}>
    <NumberInput source="Id" alwaysOn />
    <TextInput source="Name" alwaysOn />
    <TextInput source="Code" />
    <TextInput source="VATNo" alwaysOn />
</Filter>;

export default (props: any) => <List {...props} filters={<LeadFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <TextField source="Name" />
        <TextField source="Code" />
    </Datagrid>
</List>;
