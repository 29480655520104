import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, NumberInput, TextInput, BooleanInput } from 'react-admin';

const UserFilter = (props: any) => <Filter {...props}>
    <NumberInput source="Id" alwaysOn />
    <TextInput source="Login" alwaysOn />
    <BooleanInput source="Active" />
</Filter>;

export default (props: any) => <List {...props} filters={<UserFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <BooleanField source="Active" />
        <TextField source="Login" />
    </Datagrid>
</List>;
