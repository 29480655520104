import React, {useEffect, useState} from "react";

import { SelectInput } from "react-admin";

import { List } from "../services/DataService";

interface Props {
    source: string,
    record?: any
}

const InvoiceFileStateInput: React.FC<Props> = ({ source, record, ...props }) => {

    const [states, setStates] = useState([]);
    const [availableStates, setAvailableStates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // @ts-ignore
        List('InvoiceFileState')
            .then((res: any) => {
                setStates(res.value);
            })
            .catch((e:any) => console.log(e))
    }, []);

    useEffect(() => {
        if (states.length) {
            let currentStateId = record?.InvoiceFileStateId;
            let newStates = [...states];
            const availableIds: number[] = [];
            switch (currentStateId) {
                case 1:
                case 2:
                case 3:
                    availableIds.push(currentStateId, currentStateId + 1, 8);
                    break;
                case 4:
                case 5:
                case 6:
                    availableIds.push(currentStateId, currentStateId + 1);
                    break;
                case 7:
                    availableIds.push(currentStateId, 8, 13);
                    break;
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    availableIds.push(currentStateId, 9, 10, 11, 12);
                    break;
                case 13:
                    availableIds.push(currentStateId, 14);
                    break;
                case 14:
                    availableIds.push(14);
                    break;
                default:
                    break
            }
            // @ts-ignore
            setAvailableStates(newStates.filter((s: any) => availableIds.includes(s.Id) ? s: null));
            setLoading(false)
        }
    }, [states, record]);

    return(
        <>
            {!loading ?
                <SelectInput
                    source={source}
                    choices={availableStates.length ? availableStates: states}
                    options={{defaultValue: record?.InvoiceFileStateId}}
                    label={'Invoice file state'}
                    optionText={'Name'}
                    optionValue={'Id'}/>
                    :
                <span>&#8203;</span>
            }
        </>
    )
};

export default InvoiceFileStateInput;
