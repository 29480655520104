import React from 'react';
import { Link } from 'react-router-dom';

import {
    TextField, Show, TabbedShowLayout, Tab, ReferenceField, TopToolbar,
    EditButton, DateField, Button, Filter, NumberInput, TextInput, DeleteButton
} from 'react-admin';

import EmbeddedList, { useTabbedStyle } from "../../components/EmbeddedList";
import { FileListField, FileUploadPopup } from '../../components/FileComponents';
import { ContactsField } from "../../components/ContactsComponents";
import { useCVRPermissions } from "../Permissions";
import InvoiceFile from "../invoice_file";
import { ResourceModel } from "..";

const ClientShowActions: React.FC = (props: any) =>
{
    const permissions = useCVRPermissions();

    return (
        <TopToolbar>
            { permissions?.Admin && <EditButton basePath={props.basePath} record={props.data} /> }
            <FileUploadPopup resource={props.resource} record={props.data} />
            <CreateInvoiceFileButton record={props.data} />
            { permissions?.Admin && props.data && <DeleteButton basePath={props.basePath} record={props.data}/>}
        </TopToolbar>
    )
};

const ShowClient: React.FC = (props: any) =>
{
    const classes = useTabbedStyle();

    return (
        <Show actions={<ClientShowActions /> } {...props}>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="Id" />
                    <TextField source="Name" />
                    <TextField source="Code" />
                    <TextField source="AdditionalNotes" />
                    <DateField source="DateFrom" />
                    <ReferenceField source="PrimaryContactId" reference="Contact" link="show">
                        <TextField source="Name" />
                    </ReferenceField>
                    <ContactsField source="Contacts" />
                    <FileListField />
                </Tab>
                <Tab label="Invoice files" path="invoice_files">
                    <EmbeddedList className={classes.list} reference="InvoiceFile" createFilter={record => ({ ClientId: record?.Id })} filters={<InvoicesFilters />}>
                        <TextField source="Id" />
                        <TextField source="Code" />
                        <TextField source="VATYear" />
                        <ReferenceField source="ClientId" reference="Client">
                            <TextField source="Code" />
                        </ReferenceField>
                    </EmbeddedList>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default ShowClient;

const CreateInvoiceFileButton = (props: { record: ResourceModel<"Client"> | undefined }) => (
    <Button
        label="cvr.action.createInvoiceFile"
        component={Link}
        to={{
            pathname: `/${InvoiceFile.name}/create`,
            state: { record: { "ClientId": props.record?.Id } },
        }}
    >
        <InvoiceFile.icon />
    </Button>
);

const InvoicesFilters: React.FC = (props: any) => {
    return (
        <Filter {...props} style={{marginLeft: '1rem'}}>
            <NumberInput source="Id" alwaysOn />
            <TextInput source="Code" alwaysOn/>
        </Filter>
    )
};
