import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { email } from "../../utils/validators";

export default (props: any) => <Edit {...props}>
    <SimpleForm>
        <TextInput source="Id" disabled />
        <TextInput source="Name" />
        <TextInput source="Position" />
        <TextInput source="Email" validate={email()} />
        <TextInput source="Phone" />
        <TextInput source="Mobile" />
    </SimpleForm>
</Edit>;