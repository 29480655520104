import ContactIcon from '@material-ui/icons/Contacts';
import ContactCreate from './Create'
import ContactEdit from './Edit'
import ContactList from './List'
import ContactShow from './Show'
import { IdType } from '..';

export type Model =
{
    Name: string,
    Position: string,
    Email: string,
    Phone: string,
    Mobile: string,
    LeadId: IdType,
};

export default {
    name: "Contact",
    icon: ContactIcon,
    list: ContactList,
    show: ContactShow,
    edit: ContactEdit,
    create: ContactCreate,
};
