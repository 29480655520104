import React from 'react';
import { SimpleForm, TextInput, Create } from 'react-admin';
import { email } from "../../utils/validators";

export default (props: any) => <Create {...props}>
    <SimpleForm>
        <TextInput source={'Login'} />
        <TextInput source={'Email'} validate={email()}/>
    </SimpleForm>
</Create>;
