import React from "react";
import { Card, CardContent, CardActions, CardHeader, Grid, makeStyles } from "@material-ui/core";
import { Title, useTranslate } from "react-admin";
import UploadInvoiceFile from "../components/uploadInvoiceFileWizard/UploadInvoiceFile";
import RoleChange from "../components/roleChange/RoleChange";

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: 500,
        margin: "40px auto",
        filter: theme.palette.type === "dark" ? "brightness(0) invert(1)" : undefined,
    }
}));

export default () => {

    const translate = useTranslate();
    const classes = useStyles();

    return <>
        <img src="full.png" className={classes.logo} alt={'Cessco Logo'}/>
        <Grid container direction="row" spacing={4}>
            <Grid item xs={6}>
                <Card>
                    <Title title="Dashboard" />
                    <CardHeader title={translate("cvr.title")} />
                    <CardContent>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} container direction="column" spacing={4}>
                <Grid item>
                    <Card>
                        <CardContent>
                            First other content
                        </CardContent>
                        <CardActions>
                            <UploadInvoiceFile />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            Second other content
                        </CardContent>
                        <CardActions>
                            <RoleChange/>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            Third other content
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </>;
};
