import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, BooleanField, EmailField } from 'react-admin';

export default (props: any) => <Show {...props}>
    <SimpleShowLayout>
        <TextField source="Id" />
        <BooleanField source="Active" />
        <TextField source="Login" />
        <EmailField source="Email" />
        <DateField source="CreatedDate" />
        <DateField source="ModifiedDate" />
    </SimpleShowLayout>
</Show>;