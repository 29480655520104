import React, {useState, useEffect, useCallback} from 'react';
import {
    Button, FileInput, TextInput, FileField, useTranslate,
    Datagrid, TextField, UrlField, ArrayField,
    useRefresh, useNotify,
} from 'react-admin';
import { Form } from 'react-final-form';
import { Publish as UploadIcon, Close as CloseIcon, Save as SaveIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { GetEntityAttachments, Create, DeleteFile } from '../services/DataService';
import { Upload, DownloadLink } from '../services/FileService';
import { ResourceType, getResourceBase } from '../models';
import { required } from "../utils/validators";
import ConfirmActionButton from './ConfirmActionButton';

type FileUploadPopupProps =
{
    resource: ResourceType,
    record: {
        id: string
    }
};

export const FileUploadPopup = ({ resource, record }: Partial<FileUploadPopupProps>) =>
{
    const [ open, setOpen ] = React.useState(false);

    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const onClose = () =>
    {
        setOpen(false);
    };

    const onSave = (val: { file: { rawFile: File, src: string, title: string }, description: string }) =>
    {
        const res = getResourceBase(resource!) || resource;

        Create("File", {
            EntitySet: res,
            EntityId: record!.id,
            Description: val.description,
        }).then(file => Upload(file.Id, val.file.title, val.file.rawFile)
        ).then(() => {
            setOpen(false);
            refresh();
        }).catch(err => {
            const errObj = { ...err };
            if (errObj.status === 409) notify("cvr.error.duplicateAttachement", "error");
        });
    };

    return <>
        <Button label="cvr.action.uploadFile" onClick={() => setOpen(true)}>
            <UploadIcon />
        </Button>
        <Form onSubmit={onSave}>
        {(props) =>
            <Dialog maxWidth={"md"} open={open} onClose={onClose}>
                <DialogTitle>{translate("cvr.action.uploadFile")}</DialogTitle>
                <DialogContent>
                    <FileInput source="file" validate={required()}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput source="description" validate={required()} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleSubmit} label="ra.action.save">
                        <SaveIcon />
                    </Button>
                    <Button onClick={onClose} label="ra.action.close">
                        <CloseIcon />
                    </Button>
                </DialogActions>
            </Dialog>}
        </Form>
    </>;
}

type FileListFieldProps =
{
    resource: ResourceType,
    addLabel: boolean,
    label: string,
    record: {
        id: string
    }
};

export const FileListField = ({ resource, record }: Partial<FileListFieldProps>) =>
{
    const [ files, setFiles ] = useState<any[]>([]);

    const reloadFiles = useCallback(async () => {
        const newFiles = (!resource || !record)
            ? []
            : await GetEntityAttachments(resource === "Client" ? "Lead" : resource, record.id)
                .then(res => res.value.map((val: any) => ({
                    ...val,
                    Description: val.Description || "<empty>",
                    Link: DownloadLink(val.Id),
                })));
        setFiles(newFiles);
    }, [ record, resource ]);

    useEffect(() => {
        reloadFiles()
    }, [ reloadFiles ]);

    const RemoveFile = (props: Partial<{ record: any, source: any }>) => <ConfirmActionButton
        title="cvr.action.deleteFile"
        content="cvr.message.deleteFile"
        icon={<DeleteIcon color={"primary"}/>}
        action={() => DeleteFile(props.record!.Id)}
        type={'icon'}
        onSuccess={() => reloadFiles()}
    />

    return <>
        <ArrayField record={{ files }} source="files" resource="-">
            <Datagrid setSort={() => {}} selectedIds={[]}>
                <TextField source="Filename" />
                <TextField source="Description" />
                <UrlField source="Link" target="_blank" emptyText="---" />
                <RemoveFile source={"Delete"}/>
            </Datagrid>
        </ArrayField>
        <FileUploadPopup record={record} resource={resource}/>
    </>
}

FileListField.defaultProps = {
    addLabel: true,
    label: "cvr.attachments",
};
