import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { useCVRPermissions } from '../Permissions';

const EditToolbar = () =>
{
    const permissions = useCVRPermissions();

    return (
        <Toolbar>
            <SaveButton />
            { permissions?.Admin && <DeleteButton /> }
        </Toolbar>
    )
};

export default (props: any) => <Edit {...props} >
    <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="Id" disabled />
        <TextInput source="Name" />
        <TextInput source="Code" />
        <TextInput source="AdditionalNotes" />
        <DateInput source="DateFrom" />
        <ReferenceInput source="PrimaryContactId" reference="Contact">
            <SelectInput optionText="Name" />
        </ReferenceInput>
    </SimpleForm>
</Edit>;
