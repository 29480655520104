import React from "react";
import {useTranslate} from "react-admin";

import { Alert } from "@material-ui/lab";

import { ApproveDataResult } from "./StepApproveData";

type Props = {
    saveResult: ApproveDataResult | undefined
}

export const StepDone: React.FC<Props> = ({ saveResult }) => {

    const translate = useTranslate();

    if (saveResult) {
        return (
            <>
                {<h3>Invoice File:</h3>}
                {!saveResult.invoiceFileResult.errors.length ?
                    <Alert severity={'success'} >
                        <div>
                            {translate('cvr.message.invoiceFileCreated', {Code: saveResult?.invoiceFileResult?.invoiceFile.Code})}
                            <a href={`#/InvoiceFile/${saveResult?.invoiceFileResult?.invoiceFile.Id}/show`}>
                            {saveResult?.invoiceFileResult?.invoiceFile.Code}</a> )
                        </div>
                    </Alert>
                    : <Alert severity={'error'} >
                        <h3>{translate('cvr.message.invoiceFileNotCreated')}</h3>
                        {saveResult?.invoiceFileResult.errors.map(err => <div>{err.message}</div>)}
                    </Alert>
                }
                {<h3>Invoices:</h3>}
                {saveResult?.invoicesResult.map((invoiceRes, index) => {
                    if (!invoiceRes.errors.length) {
                        return (
                            <Alert severity={'success'} key={invoiceRes.invoice.Id}>
                                <div>
                                    {translate('cvr.message.invoiceCreated', {Id: invoiceRes.invoice.Id, Amount: invoiceRes.invoice.AmountOfVAT})}
                                </div>
                            </Alert>
                        )
                    } else {
                        return (
                            <Alert severity={"error"} key={index}>{translate('cvr.message.invoiceNotCreated')} {invoiceRes.errors.map(err => <div>{err.message}</div>)}</Alert>
                        )
                    }
                })}
            </>
        )
    } else return (
        <Alert severity={'error'}>{translate('cvr.message.zipUploadFailed')}</Alert>
    )
};
