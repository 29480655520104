import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, ReferenceInput, DateInput } from 'react-admin';

import AddressInput from '../../components/AddressInput';
import VatNumberInput from '../../components/VatNumberInput';

export default (props: any) => {

    return(
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="Id" disabled />
                <ReferenceInput source="InvoiceFileId" reference="InvoiceFile" disabled>
                    <SelectInput optionText="Code" />
                </ReferenceInput>
                <TextInput source="SupplierName" />
                <VatNumberInput source="SupplierVATNo" isRequired={true}/>
                <AddressInput source="SupplierAddress" />
                <TextInput source="NatureOfGoods" />
                <DateInput source="InvoiceDate" />
                <TextInput source="InvoiceNo" />
                <NumberInput source="Taxable" />
                <NumberInput source="AmountOfVAT" />
                <NumberInput source="Deductable" />
                <NumberInput source="RefundApplied" />
                <NumberInput source="ProRata" />
                <TextInput source="Note" />
            </SimpleForm>
        </Edit>
    )
};
