import SupplierList from './List';
import SupplierShow from './Show';
import SupplierCreate from "./Create";
import SupplierEdit from "./Edit";

import SupplierIcon from '@material-ui/icons/ShoppingCart';
import { AddressType } from '../address';

export type Model =
{
    Name: string,
    VATNo: string,
    Address: AddressType,
};

export default
{
    name: "Supplier",
    icon: SupplierIcon,
    list: SupplierList,
    show: SupplierShow,
    create: SupplierCreate,
    edit: SupplierEdit
};