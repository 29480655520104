import React, {useCallback, useEffect} from "react";
import {TextInput, useTranslate, getFieldLabelTranslationArgs, useInput} from "react-admin";
import {Grid, makeStyles, Paper, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

import { useForm } from "react-final-form";

import DataProvider from "../providers/DataProvider";
import { required } from "../utils/validators"

import {Model as Address} from "../models/address";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.grey[400],
    },
    paper: {
        maxWidth: 800,
        backgroundColor: "transparent",
        padding: 8,
        marginBottom: 16,
        border: `2px solid ${theme.palette.grey[200]}`,
    },
    row: {

    },
    item: {
        width: "100%",
    },
}));

type Props = {
    source: string,
    record?: any,
    resource?: string,
    label?: string,
    isRequired?: boolean
}

const AddressInput: React.FunctionComponent<Props> = ({ resource, label, source, isRequired=false}) => {

    const classes = useStyles();
    const translate = useTranslate();

    const form = useForm();

    const getInputProps = useCallback((src: string) => {
        return({
            label: `resources.Address.fields.AddressType.${src}`,
            source: `${source}.${src}`
        })}, [ source ]);

    const onSelectedAddressChange = (address: Address | undefined) => {
        if (address !== undefined) {
            form.change(`${source}.Name`, address?.AddressType?.Name);
            form.change(`${source}.Street`, address?.AddressType?.Street);
            form.change(`${source}.City`, address?.AddressType?.City);
            form.change(`${source}.PostalCode`, address?.AddressType?.PostalCode);
            form.change(`${source}.Country`, address?.AddressType?.Country);
        }
    };

    /*const handleChange = (e: React.FormEvent<HTMLInputElement>, val: 'Name' | 'Street' | 'City' | 'PostalCode' | 'Country') => {
        let newValue = e.currentTarget.value;
        form.change(source + '.' + val, newValue)
    };*/

    return(
        <Paper elevation={0} className={classes.paper}>
            <h4 style={{color: '#bdbdbd', fontWeight: 400, margin: 0, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                {translate(...getFieldLabelTranslationArgs({ label, resource, source }))}
            </h4>
            <Grid container direction="column" spacing={0}>
                <Grid item container direction="row" spacing={3} className={classes.row}>
                    <Grid item xs={6}>
                        {/*<TextInput
                            className={classes.item}
                            {...getInputProps("Name")}
                            //onChange={(e: React.FormEvent<HTMLInputElement>) => {handleChange(e, 'Name')}}
                            validate={isRequired ? required(): null}
                        />*/}
                        <NameInput {...getInputProps('Name')}
                                   isRequired={isRequired}
                                   onSelectedAddressChange={(address) => onSelectedAddressChange(address)}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            className={classes.item}
                            {...getInputProps("Street")}
                            //onChange={(e: React.FormEvent<HTMLInputElement>) => {handleChange(e, 'Street')}}
                            validate={isRequired ? required(): null}
                        /></Grid>
                </Grid>
                <Grid item container direction="row" spacing={3} className={classes.row}>
                    <Grid item xs={4}>
                        <TextInput
                            className={classes.item}
                            {...getInputProps("City")}
                            //onChange={(e: React.FormEvent<HTMLInputElement>) => {handleChange(e, 'City')}}
                            validate={isRequired ? required(): null}
                        /></Grid>
                    <Grid item xs={4}>
                        <TextInput
                            className={classes.item}
                            {...getInputProps("PostalCode")}
                            //onChange={(e: React.FormEvent<HTMLInputElement>) => {handleChange(e, 'PostalCode')}}
                            validate={isRequired ? required(): null}
                        /></Grid>
                    <Grid item xs={4}>
                        <TextInput
                            className={classes.item}
                            {...getInputProps("Country")}
                            //onChange={(e: React.FormEvent<HTMLInputElement>) => {handleChange(e, 'Country')}}
                            validate={isRequired ? required(): null}
                        /></Grid>

                </Grid>
            </Grid>
        </Paper>
    );
};

export default AddressInput;

type NameInputProps = {
    source: string,
    onSelectedAddressChange?: (selectedAddress: Address | undefined) => void,
    label?: string,
    isRequired?: boolean
}

const NameInput: React.FC<NameInputProps> = ({ ...props}) => {

    const [ suggestions, setSuggestions ] = React.useState([]);
    const [ inputValue, setInputValue ] = React.useState("");
    const [ selectedAddress, setSelectedAddress ] = React.useState< Address | undefined>(undefined);

    const {
        input: { onChange, onBlur, onFocus }
    } = useInput(props);

    const form = useForm();
    const translate = useTranslate();

    const fieldState = form.getFieldState(props.source);

    useEffect(() => {
        if (fieldState?.value) {
            setInputValue(fieldState.value);
        }
    }, [fieldState]);

    useEffect(() => {
        if (props.onSelectedAddressChange) {
            props.onSelectedAddressChange(selectedAddress)
        }
    }, [selectedAddress, props]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputValue.length) {
                DataProvider.getList('Address',
                    {filter: {'AddressType/Name': inputValue || ''}, sort: {field: 'AddressType/Name', order: 'DESC'}, pagination: {page: 1, perPage: 25}})
                    .then(res => {
                        setSuggestions(res.data)
                    });
            } else {}
        }, 800);
        return () => clearTimeout(timer);
    }, [inputValue]);

    return (
        <>
            <Autocomplete
                freeSolo
                options={suggestions || []}
                getOptionLabel={(address: any) => address.AddressType.Name || ""}
                renderOption={address => address.AddressType.Name}
                /*// @ts-ignore */
                //value={value}
                onChange={(ev: any, value: any, reason: string) => {
                    if (reason === 'select-option') {
                        setSelectedAddress(value);
                    }
                    if (reason === 'clear') {
                        setInputValue('');
                        setSelectedAddress(undefined)
                    }
                    onChange(value?.AddressType.Name)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                inputValue={inputValue}
                //onClose={(e:() => void, reason:string) => {}}
                onInputChange={(ev, value, reason) => {
                    if (reason === 'input') {
                        setSelectedAddress(undefined)
                    }
                    if (reason === 'clear') {
                        setSelectedAddress(undefined)
                    }
                    setInputValue(value);
                    onChange(value)
                }}
                renderInput={(params) => {
                    return(
                        <TextField {...params} margin="dense" variant="filled" required={props.isRequired}
                                   label={translate(props.label || 'Name')}/>
                    )
                }}
            />
        </>
    );
};
