import React from 'react';
import { List, Datagrid, TextField, Filter, NumberInput, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const ContactFilter = (props: any) => <Filter {...props}>
    <NumberInput source="Id" alwaysOn />
    <TextInput source="Name" alwaysOn />
    <TextInput source="Position" />
    <ReferenceInput source="LeadId" reference="Lead">
        <SelectInput optionText="Name" />
    </ReferenceInput>
</Filter>;

export default (props: any) => <List {...props} filters={<ContactFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <TextField source="Name" />
        <TextField source="Position" />
        <TextField source="Email" />
    </Datagrid>
</List>;
