import React from "react";
import { Themes } from "./Theme";

type ThemingType =
{
    theme: Themes,
    setTheme: (theme: Themes) => void,
}

const ThemingContext = React.createContext<ThemingType>({ theme: "light", setTheme: val => { } });

const getThemeSetting = (): Themes => {
    return localStorage.getItem("theme") === "dark"
        ? "dark"
        : "light";
}

export const ThemingProvider: React.FC = ({ children }) =>
{
    const [ theme, setTheme ] = React.useState<Themes>(getThemeSetting());

    React.useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [ theme ]);

    return <ThemingContext.Provider value={{ theme, setTheme }}>
        {children}
    </ThemingContext.Provider>;
};    

export const useTheming = () => React.useContext(ThemingContext);