import React from "react";
import { Datagrid, List } from "react-admin";
import { makeStyles } from "@material-ui/core";
import { ResourceType } from "../models";

type Props = {
    reference: ResourceType,
    record?: any,
    createFilter?: (record: any) => { [key: string]: any },
    className?: string,
    filters?: React.ReactNode | undefined,
    actions?: React.ReactNode | undefined
};

export const useTabbedStyle = makeStyles(theme => ({
    list: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
    }
}));

const EmbeddedList: React.FunctionComponent<Props> = ({ reference, createFilter, filters, actions, record, children}) =>
{
    const listProps = {
        resource: reference,
        basePath: `/${reference}`,
        filter: record && createFilter && createFilter(record),
        filters: filters,
        actions: actions || false,
        hasCreate: false,
        hasEdit: false,
        hasList: false,
        hasShow: false,
    };

    return <List {...listProps}>
        <Datagrid rowClick="show">
            {children}
        </Datagrid>
    </List>;
};

export default EmbeddedList;
