import React, {useEffect} from "react";
import { useInput } from "react-admin";
import { useForm } from 'react-final-form';

import { TextField, makeStyles, FormHelperText } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { GetVIES, VIESResult } from "../services/UtilService";

import {Model as Supplier} from "../models/supplier"

type Props = {
    source: string,
    record?: any,
    label?: string,
    onSupplierSelected?: (supplier: Supplier | null) => void,
    isRequired?: boolean
}

const useStyles = makeStyles(theme => ({
    input: {
        width: "fit-content",
        minWidth: theme.spacing(32)
    }
}));

const viesToString = (vies: VIESResult) => {
    return vies.Valid ? `${vies.Name}, ${vies.Address}` : "Unknown VAT number";
};

export default (props: Props) => {

    const [ vies, setVies ] = React.useState<VIESResult>({ Valid: false });
    const [ checking, setChecking] = React.useState<boolean>(true);
    const [ inputValue, setInputValue ] = React.useState("");

    const classes = useStyles();

    const {
        input: { onChange, onBlur, onFocus },
        meta: { touched, error },
    } = useInput(props);

    const form = useForm();

    useEffect(() => {
        if (form.getFieldState(props.source)?.value) {
            setInputValue(form.getFieldState(props.source)?.value)
        }
    }, [form, props.source]);

    useEffect(() => {
        const countryCode = inputValue.substring(0, 2);
        const vatNumber = inputValue.substring(2);
        const timer = setTimeout(() => {
            if (vatNumber.length >= 2)
            {
                setChecking(true);
                GetVIES(countryCode, vatNumber)
                    .then((value) => {
                        setVies(value);
                        setChecking(false)
                    });
            }
            else
            {
                setVies({ Valid: false });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [inputValue]);

    return (
        <>
            <Autocomplete
                freeSolo
                className={classes.input}
                options={vies.Suggestions || []}
                getOptionLabel={supplier => supplier.VATNo || ""}
                renderOption={supplier => `${supplier.VATNo} (${supplier.Name})`}
                /*// @ts-ignore */
                //value={value}
                onChange={(ev: any, value: any, reason: string) => {
                    if (reason === 'select-option') {
                        if (props.onSupplierSelected) {
                            props.onSupplierSelected(value)
                        }
                    }
                    if (reason === 'clear') {
                        setInputValue('')
                    }
                    onChange(value?.VATNo)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                inputValue={inputValue}
                //onClose={(e:() => void, reason:string) => {}}
                onInputChange={(ev, value, reason) => {
                    if (reason === 'input') {
                        if (props.onSupplierSelected) {
                            props.onSupplierSelected(null)
                        }
                    }
                    setInputValue(value);
                    onChange(value)
                }}
                renderInput={(params) => {
                    return(
                        <TextField {...params} margin="dense" variant="filled" required={props.isRequired}
                            error={!!(touched && (error || !vies.Valid)) && !checking}
                            label={props.label || 'VAT Number'}/>
                        )
                }}
            />
            <FormHelperText error={!!(touched && (error || !vies.Valid))} style={{marginLeft: '10px', marginTop: '0px'}}>
                {touched ? error || viesToString(vies) || <span>&#8203;</span> : vies.Valid ? viesToString(vies):<span>&#8203;</span>}
            </FormHelperText>
        </>
    );
}
