import React from 'react';
import { SimpleForm, TextInput, Create } from 'react-admin';

import AddressInput from "../../components/AddressInput";
import VatNumberInput from "../../components/VatNumberInput";

import { required } from "../../utils/validators";

export default (props: any) => <Create {...props}>
    <SimpleForm>
        <TextInput source="Name" validate={required()}/>
        <VatNumberInput source="VATNo"/>
        <AddressInput source={"Address"}/>
    </SimpleForm>
</Create>;