import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput } from 'react-admin';
import { email } from '../../utils/validators';

export default (props: any) => <Edit {...props}>
    <SimpleForm>
        <TextInput source={'Id'} disabled />
        <TextInput source={'Login'} />
        <TextInput source={'Email'} validate={email()}/>
        <DateInput source={'CreatedDate'} disabled/>
        <DateInput source={'ModifiedDate'} disabled/>
    </SimpleForm>
</Edit>;
