import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
} from 'react-admin';

import InvoiceFileStateInput from "../../components/InvoiceFileStateInput";
import CurrencyInput from "../../components/CurrencyInput";
import { useCVRPermissions } from '../Permissions';

const InvoiceFileEdit = (props: any) =>
{
    const [countries, setCountries] = React.useState<any[]>([]);
    const permissions = useCVRPermissions();
    const dataProvider = useDataProvider();

    const transform = (data: any) => {
        const specialStateIds: number[] = [9, 10, 11, 12];

        const getNewCode = () => {
            const onlyBasicCode = data.Code.split(".")[0];
            if (data.InvoiceFileStateId === 9) return `${onlyBasicCode}.R1`;
            if (data.InvoiceFileStateId === 10) return `${onlyBasicCode}.R2`;
            if (data.InvoiceFileStateId === 11) return `${onlyBasicCode}.IR`;
            if (data.InvoiceFileStateId === 12) return `${onlyBasicCode}.CN`;
        };

        return {
            ...data,
            Code: specialStateIds.includes(data.InvoiceFileStateId) ? getNewCode() : data.Code.split(".")[0],
        };
    };

    React.useEffect(() => {
        let mounted = true;
        const getData = async () => {
            const data = await dataProvider.getList('Country', {
                filter: {},
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'id', order: 'ASC' }
            });
            if (mounted) setCountries(data.data);
        };

        getData();
        return () => { mounted = false };
    }, [dataProvider]);

    return(
        <Edit {...props} transform={transform}>
            <SimpleForm>
                <TextInput source="Id" disabled />
                <TextInput source="Code" disabled />
                <ReferenceInput source="ClientId" reference="Client" filterToQuery={(searchText:any) => ({'Name': searchText})}>
                    <AutocompleteInput optionText={'Name'} optionValue={'Id'}/>
                </ReferenceInput>
                <AutocompleteInput
                    source="SalesCountry"
                    choices={countries}
                    optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
                    suggestionLimit={1000}
                />
                <CurrencyInput source={'SalesCurrency'} relatedSource={'SalesCountry'} optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')': ''}/>
                <ReferenceInput source="SubmitCountry" reference="Country">
                    <AutocompleteInput optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')': ''} />
                </ReferenceInput>
                <CurrencyInput source={'SubmitCurrency'} relatedSource={'SubmitCountry'} optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')': ''}/>
                <ReferenceInput source="InvoiceFileTypeId" reference="InvoiceFileType">
                    <SelectInput optionText="Name" />
                </ReferenceInput>
                <InvoiceFileStateInput source={"InvoiceFileStateId"}/>
                { permissions?.Admin &&
                    <ReferenceInput source="InvoiceFileStateId" reference="InvoiceFileState">
                        <SelectInput optionText="Name" />
                    </ReferenceInput>
                }
                <NumberInput source="VATYear" />
                <DateInput source="ApplicationFrom" />
                <DateInput source="ApplicationTo" />
                <TextInput source="Note" />
            </SimpleForm>
        </Edit>
    )
};

export default InvoiceFileEdit;
