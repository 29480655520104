import React from "react";
import { Button, useTranslate, useRefresh, useNotify, FileInput, FileField, TextInput, ReferenceInput, SelectInput, DateInput } from "react-admin";
import { Form } from "react-final-form";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Save, Close } from "@material-ui/icons";
import Invoice from "../invoice";
import { required } from "../../utils/validators";
import { Create } from "../../services/DataService";
import { Upload } from "../../services/FileService";
import { AddressType } from "../address";
import { IdType, DateType } from "..";
import AddressInput from "../../components/AddressInput";

type FormData =
{
    file: { rawFile: File, src: string, title: string },
    InvoiceFileId: IdType,
    InvoiceDate: DateType,
    SupplierName: string,
    SupplierVATNo: string,
    SupplierAddress: AddressType,
};

type Props =
{
    invoiceFileId: IdType,
};

const CreatePopup: React.FunctionComponent<Props> = ({ invoiceFileId }) =>
{
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const [ open, setOpen ] = React.useState(false);



    const onSave = (data: FormData) =>
    {
        const { file, ...invoice } = data;
        Create("Invoice", invoice)
            .then(newInvoice => Create("File", { 
                EntitySet: "Invoice",
                EntityId: newInvoice.Id, 
            }))
            .then(newFile => Upload(newFile.Id, file.title, file.rawFile))
            .then(() => {
                setOpen(false);
                refresh();
            })
            .catch((err: { message: string }) => notify(err.message, "error"));
    }

    return <>
        <Button label="cvr.action.createInvoice" onClick={() => setOpen(true)}>
            <Invoice.icon />
        </Button>
        <Form onSubmit={onSave}>
        {(props) => 
            <Dialog maxWidth={"md"} open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{translate("cvr.action.createInvoice")}</DialogTitle>
                <DialogContent>
                    <ReferenceInput source="InvoiceFileId" reference="InvoiceFile" initialValue={invoiceFileId} disabled validate={required()}>
                        <SelectInput optionText="Code" />
                    </ReferenceInput>
                    <DateInput source="InvoiceDate" validate={required()} />
                    <TextInput source="SupplierName" validate={required()} />
                    <TextInput source="SupplierVATNo" validate={required()} />
                    <AddressInput source="SupplierAddress" />
                    <FileInput source="file" validate={required()}>
                        <FileField source="src" title="title" />
                    </FileInput>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleSubmit} label="ra.action.save">
                        <Save />
                    </Button>
                    <Button onClick={() => setOpen(false)} label="ra.action.close">
                        <Close />
                    </Button>
                </DialogActions>
            </Dialog>}
        </Form>
    </>;
};

export default CreatePopup;