import React from "react";
import { 
    ArrayField, Datagrid, TextField, EmailField, BooleanField, useNotify, useRefresh
} from "react-admin";

import {Delete} from '@material-ui/icons';

import AddContactPopup from "./AddContactPopup";

import { DeleteRelation, Update } from "../services/DataService";

import { ResourceType, getRelationFields } from "../models";

type ContactsFieldProps =
{
    source: string,
    resource: ResourceType,
    addLabel: boolean,
    label: string,
    record: {
        id: string,
        PrimaryContactId: string,
    }
};

export const ContactsField = (props: Partial<ContactsFieldProps>) => {
    
    const { source, resource, addLabel, label, record } = props;

    return (
        <>
            <ArrayField source={source} label={label} addLabel={addLabel} record={record} resource="-">
                <Datagrid
                    setSort={() => { }}
                    selectedIds={[]}
                    //rowClick={(_id: any, _basePath: any, record: any) => linkToRecord("/Contact", record.Id, 'show')}
                >
                    <RedirectField source="Name" />
                    <TextField source="Position" />
                    <EmailField source="Email" />
                    <TextField source="Phone" />
                    <TextField source="Mobile" />
                    <PrimaryContactField source="Primary" res={resource} companyRecord={record} />
                    <DeleteContactField source="Delete" companyId={record?.id} res={resource} />
                </Datagrid>
            </ArrayField>
            {resource? <div style={{margin: '10px auto 0 auto'}}><AddContactPopup record={record} resource={resource}/></div>: null}
        </>
    )
};

ContactsField.defaultProps = {
    addLabel: true,
};

const PrimaryContactField = ({ record, res, companyRecord }: any) => {

    const refresh = useRefresh();
    const notify = useNotify();

    const getModel = (resource: ResourceType, rec: any) => {
        const newRec = {...rec};
        delete newRec.id;
        // @ts-ignore
        for (let field of getRelationFields(resource)) {
            delete newRec[field]
        }
        return newRec
    };

    const primaryClicked = () => {
        if (record && companyRecord ) {
            try {
                let newRecord = getModel(res, companyRecord);
                if (newRecord.PrimaryContactId === record.Id) {
                    delete newRecord.PrimaryContactId;
                    notify('cvr.message.primaryContactRemoved', 'info')
                } else {
                    newRecord.PrimaryContactId = record.Id;
                }
                Update(res, companyRecord.Id, newRecord)
                    .then(() => {
                        refresh();
                        notify('cvr.message.primaryContactSet', 'info');
                    })
            } catch (err) {
                notify(err.message, 'error')
            }

        }
    };

    return <BooleanField record={{ primary: companyRecord.PrimaryContactId === record?.Id }}
                         color={'primary'} source="primary" onClick={() => primaryClicked()}
                         style={{cursor: 'pointer'}}/>
};

const RedirectField = ({record}: any) => {

    if (record) {
        return (
            <a href={'#/Contact/' + record.Id + '/show'} style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#EC0B80',
            }}>
                {record?.Name || 'Contact name'}
            </a>
        )
    } else return null
};

const DeleteContactField = ({record, res, companyId }: any) => {

    const refresh = useRefresh();
    const notify = useNotify();

    const deleteContact = () => {
        if (record && res && companyId) {
            try {
                DeleteRelation(res, companyId, 'Contacts', record.Id)
                    .then(() => {
                        refresh();
                        notify('cvr.message.contactDeleted', 'success')
                    })
            } catch (err) {
                notify(err.message, 'error')
            }
        }
    };

    return (
        <Delete onClick={() => deleteContact()} color={'primary'} style={{cursor: 'pointer'}}/>
    )
};
