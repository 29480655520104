import React from 'react';
import { AppBar } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import UserMenu from "./UserMenu";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
    },
});

export default (props: any) =>
{
    const classes = useStyles();

    return (
        <AppBar {...props} userMenu={<UserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img src="lite_white.png" style={{ height: 28 }} alt={'Cessco - white logo'}/>
            <span className={classes.spacer} />
        </AppBar>
    );
};
