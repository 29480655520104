import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import AddressInput from '../../components/AddressInput';
import VatNumberInput from "../../components/VatNumberInput";

const EditSupplier = (props: any) => {

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="Id" disabled />
                <TextInput source="Name" />
                <VatNumberInput source={'VATNo'} label={'VAT number'} />
                <AddressInput source="Address" />
            </SimpleForm>
        </Edit>
    )
};

export default EditSupplier;
