import CurrencyIcon from "@material-ui/icons/AttachMoney";
import CurrencyList from "./List";

export type Model =
{
    Name: string,
    Code: string,
};

export default
{
    name: "Currency",
    icon: CurrencyIcon,
    list: CurrencyList,
};