import { required, email, minValue, maxValue, number } from "react-admin";

const year = (message: string = "Must be valid year") => (value: any) => 
    number(message)(value) 
    || minValue(1900, message)(value) 
    || maxValue(2100, message)(value);

export
{
    required,
    email,
    year,
};