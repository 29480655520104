import { fetchJson } from './ServiceUtils';
import Config from '../Config';

const URL = () => Config.api.url + Config.api.file;

export const DownloadLink = (id: string) =>
{
    return `${URL()}/Download?fileId=${id}`;
}

export const Upload = async (id: string, name: string, content: any) =>
{
    return fetchJson(`${URL()}/Upload?fileId=${id}&filename=${name}`, {
        method: "POST",
        body: content,
    });
}
