import React from 'react';
import { SimpleForm, Create, ReferenceInput, TextInput, NumberInput, SelectInput, DateInput } from 'react-admin';

import SupplierPicker from "../../components/SupplierPicker";

export default (props: any) => {

    return (
        <Create {...props}>
            <SimpleForm submitOnEnter={false}>
                <ReferenceInput source="InvoiceFileId" reference="InvoiceFile">
                    <SelectInput optionText="Code"  />
                </ReferenceInput>
                <SupplierPicker validation={{vatNumber: true, nameInput: true, addressInput: true}}/>
                <TextInput source="NatureOfGoods" />
                <DateInput source="InvoiceDate" />
                <TextInput source="InvoiceNo" />
                <NumberInput source="Taxable" />
                <NumberInput source="AmountOfVAT" />
                <NumberInput source="Deductable" />
                <NumberInput source="RefundApplied" />
                <NumberInput source="ProRata" />
                <TextInput source="Note"/>
            </SimpleForm>
        </Create>
    )
};
