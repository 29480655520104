import { createMuiTheme } from '@material-ui/core/styles';

export type Themes = "light" | "dark";

export default (theme: Themes) => createMuiTheme({
    palette: {
        type: theme,
        primary: {
            main: "#EC0B80", //"#23B9E5",
        },
        secondary: {
            main: "#424F5C",
        },
        background: {
            paper: theme === "light" ? "#FFFFFF" : "#303942",
            default: theme === "light" ? "#F4F4F4" : "#0B0B0B" //"#D9DDE1", //"#4D5965",
        }
    },
})