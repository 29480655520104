import React, { useCallback } from "react";
import {
    SimpleForm, Create, ReferenceInput, DateInput, NumberInput,
    SelectInput, AutocompleteInput, useDataProvider,
} from "react-admin";
import { useForm } from "react-final-form";

import CurrencyInput from "../../components/CurrencyInput";

import { required, year } from "../../utils/validators";

const requiredYear = [ required(), year() ];

const InvoiceFileCreate = (props: any) => {
    const [countries, setCountries] = React.useState<any[]>([]);
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        let mounted = true;
        const getData = async () => {
            const data = await dataProvider.getList('Country', {
                filter: {},
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'id', order: 'ASC' }
            });
            if (mounted) setCountries(data.data);
        };

        getData();
        return () => { mounted = false };
    }, [dataProvider]);

    return <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="ClientId" reference="Client" validate={required()} filterToQuery={(searchText: any) => ({ 'Name': searchText })}>
                <AutocompleteInput optionText={(c: any) => c ? c?.Name : ''} optionValue={'Id'} />
            </ReferenceInput>
            <AutocompleteInput
                source="SubmitCountry"
                choices={countries}
                optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
                suggestionLimit={1000}
                validate={required()}
            />
            <CurrencyInput source={'SubmitCurrency'} relatedSource={'SubmitCountry'}
                validate={required()} optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''} />
            <AutocompleteInput
                source="SalesCountry"
                choices={countries}
                optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
                suggestionLimit={1000}
                validate={required()}
            />
            <CurrencyInput source={'SalesCurrency'} relatedSource={'SalesCountry'}
                validate={required()} optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''} />
            <VATYearInput source="VATYear" validate={requiredYear} />
            <DateInput source="ApplicationFrom" disabled />
            <DateInput source="ApplicationTo" disabled />
            <ReferenceInput source="InvoiceFileTypeId" reference="InvoiceFileType" validate={required()} initialValue={1}>
                <SelectInput optionText="Name" />
            </ReferenceInput>
            <ReferenceInput source="InvoiceFileStateId" reference="InvoiceFileState" validate={required()} initialValue={1}>
                <SelectInput optionText="Name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>;
};

export default InvoiceFileCreate;

const VATYearInput = (props: any) => {

    const form = useForm();

    const handleYearChange = useCallback(() => {
        const field = form.getFieldState("VATYear");
        const year = field?.value && field?.valid && typeof field.value === "number" ? field.value : undefined;
        form.change("ApplicationFrom", year ? new Date(Date.UTC(year, 0, 1)) : undefined);
        form.change("ApplicationTo", year ? new Date(Date.UTC(year, 11, 31)) : undefined);
    }, [ form ]);

    return <NumberInput {...props} onChange={handleYearChange} />;
};
