import { Model as LeadModel } from "./lead";
import { Model as ClientModel } from "./client";
import { Model as ContactModel } from "./contact";
import { Model as UserModel } from "./user";
import { Model as FileModel } from "./file";
import { Model as AddressModel } from "./address";
import { Model as CurrencyModel } from "./currency";
import { Model as CountryModel } from "./country";
import { Model as Country_CurrencyModel } from "./country_currency";
import { Model as InvoiceModel } from "./invoice";
import { Model as InvoiceFileModel } from "./invoice_file";
import { Model as SupplierModel } from "./supplier";

export type JsonResourceType = "Currency" | "Country" | "Country_Currency";
export type DBResourceType = "Lead" | "Client" | "Contact" | "User" | "File" | "Address" | "Currency" | "Country" | "Invoice" | "InvoiceFile" | "Supplier";
export type ResourceType = DBResourceType | JsonResourceType;
export type IdType = string;
export type DateType = string;

export type ResourceModel<T extends ResourceType> = { Id: IdType } & (
    T extends "Lead" ? LeadModel :
    T extends "Client" ? ClientModel :
    T extends "Contact" ? ContactModel:
    T extends "User" ? UserModel :
    T extends "File" ? FileModel :
    T extends "Address" ? AddressModel :
    T extends "Currency" ? CurrencyModel :
    T extends "Country" ? CountryModel :
    T extends "Country_Currency" ? Country_CurrencyModel :
    T extends "Invoice" ? InvoiceModel :
    T extends "InvoiceFile" ? InvoiceFileModel :
    T extends "Supplier" ? SupplierModel :
    never
    );

export const getResourceBase = (resource: ResourceType): ResourceType | undefined =>
{
    if (resource === "Client") return "Lead";
    return undefined;
}

export const getRelationFields = (resource: ResourceType): string[] | undefined =>
{
    if (resource === "Lead" || resource === "Client") return [ "Contacts", "PrimaryContact" ];
    else if (resource === "Contact") return ["Leads"];
    return undefined;
}
