import React, {cloneElement} from 'react';
import { List, Datagrid, TextField, ReferenceField, TextInput, NumberInput,
    Filter, ReferenceInput, SelectInput, TopToolbar, CreateButton, useDataProvider, AutocompleteInput } from 'react-admin';
import UploadInvoiceFile from "../../components/uploadInvoiceFileWizard/UploadInvoiceFile";

const ListActions = (props:any) => {

    const { filters, resource, showFilter, displayedFilters, filterValues, basePath} = props;

    return (
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <UploadInvoiceFile />
        </TopToolbar>
    );
};

const InvoiceFileFilter = (props: any) => {
    const [countries, setCountries] = React.useState<any[]>([]);
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        let mounted = true;
        const getData = async () => {
            const data = await dataProvider.getList('Country', {
                filter: {},
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'id', order: 'ASC' }
            });
            if (mounted) setCountries(data.data);
        };

        getData();
        return () => { mounted = false };
    }, [dataProvider]);

    return <Filter {...props}>
        <TextInput source="Code" alwaysOn />
        <ReferenceInput source="ClientId" reference="Client" alwaysOn>
            <SelectInput optionText="Name" />
        </ReferenceInput>
        <AutocompleteInput
            source="SalesCountry"
            choices={countries}
            optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
            suggestionLimit={1000}
        />
        <AutocompleteInput
            source="SubmitCountry"
            choices={countries}
            optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
            suggestionLimit={1000}
        />
        <NumberInput source="VATYear" />
    </Filter>;
};

export default (props: any) => <List {...props} filters={<InvoiceFileFilter />} exporter={false} actions={<ListActions/>}>
    <Datagrid rowClick="show">
        <TextField source="Id" />
        <TextField source="Code" />
        <TextField source="VATYear" />
        <ReferenceField source="ClientId" reference="Client">
            <TextField source="Name" />
        </ReferenceField>
    </Datagrid>
</List>;
