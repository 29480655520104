import ClientList from './List';
import ClientEdit from './Edit';
import ClientShow from './Show';
import ClientIcon from '@material-ui/icons/Person';
import { Model as LeadModel } from '../lead';

export type Model = LeadModel &
{
    DateFrom: string,
};

export default {
    name: "Client",
    icon: ClientIcon,
    list: ClientList,
    show: ClientShow,
    edit: ClientEdit,
}
