import LeadIcon from '@material-ui/icons/PersonOutline';
import LeadList from './List';
import LeadShow from './Show';
import LeadEdit from './Edit';
import LeadCreate from './Create';
import { IdType } from '..';
import { Model as ContactModel } from '../contact';

export type Model =
{
    Name: string,
    Code: string,
    AdditionalNotes: string,
    PrimaryContactId: IdType,
    Contacts: ContactModel[],
};

export default {
    name: "Lead",
    icon: LeadIcon,
    list: LeadList,
    show: LeadShow,
    edit: LeadEdit,
    create: LeadCreate,
};
