import React from 'react';
import { 
    TextField, SimpleShowLayout, Show,
    TopToolbar, EditButton, UrlField, ReferenceField, EmailField, DeleteButton
} from 'react-admin';
import { FileListField, FileUploadPopup } from '../../components/FileComponents';
import { ContactsField } from "../../components/ContactsComponents";
import ConfirmActionButton from '../../components/ConfirmActionButton';
import { ConvertLeadToClient } from '../../services/DataService';
import ConvertIcon from '@material-ui/icons/CheckCircle';
import AddressField from '../../components/AddressField';
import { useCVRPermissions } from '../Permissions';

const ConvertToClientPopup = (props: { record: { Id: number }}) =>
{
    return <ConfirmActionButton
        title="cvr.action.convertLeadToClient"
        content="cvr.message.convertLeadToClient"
        icon={<ConvertIcon />}
        action={() => ConvertLeadToClient(props.record!.Id)}
        type={'button'}
        onSuccess={async () => { console.log("DONE"); }}
    />;
};

const LeadShowActions = ({ basePath, data, resource }: any) => {

    const permissions = useCVRPermissions();

    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data}/>
            <FileUploadPopup resource={resource} record={data} />
            { permissions?.Admin && <ConvertToClientPopup record={data} /> }
            { permissions?.Admin && data && <DeleteButton basePath={basePath} record={data}/> }
        </TopToolbar>
    )
};

export default (props: any) => <Show {...props} actions={<LeadShowActions {...props}/>}>
        <SimpleShowLayout>
            <TextField source="Id" />
            <TextField source="Name" />
            <TextField source="Code" />
            <TextField source="Phone" />
            <EmailField source="Email" />
            <UrlField source="Website" emptyText="---" />
            <ReferenceField source="SalesCountry" reference="Country">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="VATNo" label="VAT number"/>
            <AddressField source="PostalAddress" />
            <AddressField source="VisitingAddress" />
            <ContactsField source="Contacts" />
            <FileListField />
            <TextField source="AdditionalNotes" />
        </SimpleShowLayout>
    </Show>
