import { fetchUtils } from "react-admin";
import get from "lodash/get";

export const headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
});

type Error = {
    status: number,
    message?: string,
}

const throwError = (err: any): never =>
{
    const parseMessage = (error: any) => get(error, "message", undefined);

    const parseInnerError = (error: any) =>
    {
        let innererror = get(error, ["body", "odata.error", "innererror"], {});
        while (innererror.internalexception) innererror = innererror.internalexception;
        const msg = get(innererror, "message", undefined);

        if (!msg) return undefined;
        else if (msg.indexOf("duplicate key row") > -1) return "cvr.error.duplicity";
        else return msg;
    }

    const error = {...(err || {})};

    const parsed: Error = {
        status: get(error, "status", 0),
        message: parseMessage(error)
            || parseInnerError(error)
            || undefined,
    };

    const duplicateLeadError: Error = {
        status: 409,
        message: "cvr.error.leadAlreadyExists",
    };

    console.log("fetchJson: error caught\n", error, parsed);
    throw parsed.status === 409 ? duplicateLeadError : parsed;
}

export const fetchJson = async (url: string, options: Object) =>
{
    return fetchUtils.fetchJson(url, { credentials: "include", ...options })
        .then((resp: any) => resp.json)
        .catch(throwError);
}
