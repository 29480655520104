import React, { useCallback } from "react";
import get from "lodash/get";
import { TextField, SimpleShowLayout } from "react-admin";

type Props =
{
    addLabel?: boolean,
    source?: string,
    label?: string,
    basePath?: string,
    record?: any,
}

const AddressField: React.FunctionComponent<Props> = ({ addLabel, label, source, basePath, record }) =>
{
    const address = record && source && get(record, source);

    const getFieldProps = useCallback((src: string) => ({
        source: src,
        label: `resources.Address.fields.AddressType.${src}`,
    }), []);

    return <SimpleShowLayout record={address}>
        <TextField {...getFieldProps("Name")} />
        <TextField {...getFieldProps("Street")} />
        <TextField {...getFieldProps("City")} />
        <TextField {...getFieldProps("PostalCode")} />
        <TextField {...getFieldProps("Country")} />
    </SimpleShowLayout>;
};

AddressField.defaultProps =
{
    addLabel: true,
}

export default AddressField;