import InvoiceIcon from "@material-ui/icons/FolderOpen";
import InvoiceList from "./List";
import InvoiceShow from "./Show";
import InvoiceEdit from "./Edit";
import InvoiceCreate from "./Create";
import { AddressType } from "../address";
import { IdType, DateType } from "..";

export type Model =
{
    InvoiceFileId: IdType,
    SupplierName: string,
    SupplierVATNo: string,
    SupplierAddress: AddressType,
    NatureOfGoods: string,
    InvoiceDate: DateType,
    InvoiceNo: string,
    Taxable: number,
    AmountOfVAT: number,
    Deductable: number,
    RefundApplied: number,
    ProRata: number,
    Note: string,
};

export default
{
    name: "Invoice",
    icon: InvoiceIcon,
    list: InvoiceList,
    show: InvoiceShow,
    edit: InvoiceEdit,
    create: InvoiceCreate,
};