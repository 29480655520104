import React from 'react';
import { TextField, Show, SimpleShowLayout, NumberField, ReferenceField, DateField } from 'react-admin';
import AddressField from '../../components/AddressField';
import { FileListField } from '../../components/FileComponents';

export default (props: any) => <Show {...props}>
    <SimpleShowLayout>
        <TextField source="Id" />
        <ReferenceField source="InvoiceFileId" reference="InvoiceFile">
            <TextField source="Code" />
        </ReferenceField>
        <TextField source="SupplierName" />
        <TextField source="SupplierVATNo" />
        <AddressField source="SupplierAddress" />
        <TextField source="NatureOfGoods" />
        <DateField source="InvoiceDate" />
        <TextField source="InvoiceNo" />
        <NumberField source="Taxable" />
        <NumberField source="AmountOfVAT" />
        <NumberField source="Deductable" />
        <NumberField source="RefundApplied" />
        <NumberField source="ProRata" />
        <TextField source="Note" />
        <FileListField />
    </SimpleShowLayout>
</Show>;