import InvoiceFileIcon from "@material-ui/icons/Folder";
import InvoiceFileList from "./List";
import InvoiceFileShow from "./Show";
import InvoiceFileEdit from "./Edit";
import InvoiceFileCreate from "./Create";
import { IdType, DateType } from "..";

export type Model =
{
    Code: string
    ClientId: IdType
    SalesCountry?: IdType
    SalesCurrency?: IdType
    SubmitCountry?: IdType
    SubmitCurrency?: IdType
    InvoiceFileTypeId: IdType
    InvoiceFileStateId?: IdType
    VATYear: number,
    ApplicationFrom: DateType,
    ApplicationTo: DateType,
    Note: string,
};

export default
{
    name: "InvoiceFile",
    icon: InvoiceFileIcon,
    list: InvoiceFileList,
    show: InvoiceFileShow,
    edit: InvoiceFileEdit,
    create: InvoiceFileCreate,
};