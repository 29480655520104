import React from "react";
import { 
    UserMenu, useLocale, useSetLocale, useGetOne, useTranslate
} from "react-admin";
import { Switch, Grid, Typography } from "@material-ui/core";
import { useTheming } from './ThemingProvider';

type LabeledSwitchProps = {
    left: string,
    right: string,
    checked?: boolean,
    onChange: (value: boolean) => void
}

const LabeledSwitch = ({ left, right, checked, onChange }: LabeledSwitchProps) =>
{
    return <Typography component="div" style={{ padding: 8 }}>
        <Grid component="label" container alignItems="center" spacing={1} justify="center">
            <Grid item>{left}</Grid>
            <Grid item>
                <Switch color="primary" checked={checked} onChange={(e, v) => onChange(v)} />
            </Grid>
            <Grid item>{right}</Grid>
        </Grid>
    </Typography>;
}

const LocaleSwitch = React.forwardRef((props, ref) =>
{
    const locale = useLocale();
    const setLocale = useSetLocale();
    const translate = useTranslate();

    const handleLocaleChange = (val: boolean) =>
    {
        const newLocale = val ? "cs" : "en";
        setLocale(newLocale);
        localStorage.setItem("lang", newLocale);
    };

    return <LabeledSwitch
        left={translate("cvr.settings.langs.en")}
        right={translate("cvr.settings.langs.cs")}
        checked={locale === "cs"}
        onChange={handleLocaleChange}
    />
});

const ThemeSwitch = React.forwardRef((props, ref) =>
{
    const { theme, setTheme } = useTheming();
    const translate = useTranslate();
    
    const handleThemeChange = (val: boolean) =>
    {
        setTheme(val ? "dark" : "light");
    };

    return <LabeledSwitch 
        left={translate("cvr.settings.themes.light")}
        right={translate("cvr.settings.themes.dark")}
        checked={theme === "dark"} 
        onChange={handleThemeChange} 
    />
});

const useLoggedUser = () => {
    const [ user, setUser ] = React.useState<any>();
    const id = localStorage.getItem("uid") || 0;
    const { data } = useGetOne("User", id);
    
    React.useEffect(() => {
        if (!!data) setUser(data);
    }, [ data ]);

    return user;
}

const UserInfo = React.forwardRef((props, ref) =>
{
    const user = useLoggedUser();

    return <Typography align="center">
        {user && `${user.Email} (${user.Id})`}
    </Typography>;
});

export default (props: any) =>
{
    return <UserMenu {...props}>
        <UserInfo />
        <LocaleSwitch />
        <ThemeSwitch />
    </UserMenu>
};