import React from 'react';
import { SimpleForm, TextInput, Create, AutocompleteInput, useDataProvider } from 'react-admin';
import { required } from "../../utils/validators";

const LeadCreate = (props: any) => {
    const [countries, setCountries] = React.useState<any[]>([]);
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        let mounted = true;
        const getData = async () => {
            const data = await dataProvider.getList('Country', {
                filter: {},
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'id', order: 'ASC' }
            });
            if (mounted) setCountries(data.data);
        };

        getData();
        return () => { mounted = false };
    }, [dataProvider]);

    return <Create {...props}>
        <SimpleForm>
            <TextInput source="Name" validate={required()} />
            <AutocompleteInput
                source="SalesCountry"
                choices={countries}
                optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
                suggestionLimit={1000}
                validate={required()}
            />
            <TextInput source="AdditionalNotes" />
        </SimpleForm>
    </Create>;
};

export default LeadCreate;
