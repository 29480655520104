import React, {useEffect, useState} from "react";
import SaveAs from "file-saver";

import { Button } from "react-admin";
import GetApp from '@material-ui/icons/GetApp';
import { MenuItem, Menu } from '@material-ui/core';

import {List} from "../../services/DataService";
//import dataProvider from "../../providers/DataProvider";

import {Model as InvoiceFile} from "../../models/invoice_file";
import {Model as Invoice} from "../../models/invoice";

//import Config from "../../Config";

interface Props {
    data: any;
}

const ExportXmlFile: React.FC<Props> = ({ data }) => {

    const [invoicesData, setInvoicesData] = useState([]);

    const [exportXml, setExportXml] = useState(false);
    const [country, setCountry] = useState<'CZ' | 'SK' | 'RO' | undefined>(undefined);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItemClicked = (country: 'CZ' | 'SK' | 'RO') => {
        setCountry(country);
        setExportXml(true);
        handleClose();
    };

    useEffect( () => {
        if (data && exportXml) {
            List('Invoice',{$filter: `InvoiceFileId eq ${data.Id}`})
                .then(res => {
                    if (res.value?.length > 0) {
                        // @ts-ignore
                        setInvoicesData(res.value)
                    }
                });
            /*// also working
            dataProvider.getList('Invoice', {filter: {InvoiceFileId: data.Id},
                pagination: {page: 1, perPage: 50},
                sort: {field: 'Id', order: 'ASC'}})
                .then(res => setInvoicesData(res.data))
                .catch(err => console.log(err));*/

            /*// also working
            fetch(`${Config.api.url + Config.api.data}/Invoice?$format=json&$filter=InvoiceFileId eq ${data.Id}`)
                .then(resp => resp.json()
                    .then(json => {
                        const currentInvoices = json.value;
                        setInvoicesData(currentInvoices)
                    })
                    .catch(err => console.log(err)))*/
        }
    }, [data, exportXml]);

    useEffect(() => {
        if (exportXml) {
            if (data !== undefined && invoicesData.length && country !== undefined) {
                // @ts-ignore
                createXml(data, invoicesData, country);
                setExportXml(false)
            }
        }
    }, [exportXml, invoicesData, data, country]);

    return(
        <>
            <Button label={'ra.action.export'} onClick={handleClick}>
                <GetApp/>
            </Button>
            <Menu keepMounted
                id="fade-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                style={{marginLeft: '1.5rem', marginTop: '2.2rem'}}
            >
                <MenuItem onClick={() => menuItemClicked('CZ')}>CZ</MenuItem>
                <MenuItem onClick={() => menuItemClicked('SK')}>SK</MenuItem>
                <MenuItem onClick={() => menuItemClicked('RO')}>RO</MenuItem>
            </Menu>
        </>

    )
};

export default ExportXmlFile;

const createXml = ( invoiceFileData: InvoiceFile, invoicesData: Invoice[], country: 'CZ' | 'SK' | 'RO') => {
    switch (country) {
        case "CZ":
            exportCzXml(invoiceFileData, invoicesData);
            break;
        case "SK":
            exportSkXml(invoiceFileData, invoicesData);
            break;
        case "RO":
            exportRoXml(invoiceFileData, invoicesData);
            break;
        default:
            break;
    }
};

const exportCzXml = ( invoiceFileData: InvoiceFile, invoicesData: Invoice[] ) => {

    let vety3 = '';
    let vetyE = '';
    let vetyF = '';

    for (let i=0; i <invoicesData.length; i++) {

        vety3 = vety3 + `<Veta3 datum_vydani="${invoicesData[i]?.InvoiceDate}" 
                                mena_dph="EUR" odp_castka="222.41" zdan_castka="1170.59" dph_castka="222.41" 
                                dod_ident="${invoicesData[i]?.SupplierVATNo}" ref_cislo="27400800361" 
                                zj_doklad="N" seq_cislo="1" dod_ident_typ="T"/>`;

        vetyE = vetyE + `<VetaE k_stat="DE" psc="${invoicesData[i]?.SupplierAddress?.PostalCode}" 
                                ulice="${invoicesData[i]?.SupplierAddress?.Street}" 
                                seq_cislo="${i + 1}" cis_domu="" 
                                mesto="${invoicesData[i]?.SupplierAddress?.City}" 
                                dod_ident_zeme="${invoicesData[i]?.SupplierAddress?.Country}" 
                                dod_prijmeni="${invoicesData[i]?.SupplierAddress?.Name}"/>`;

        vetyF = vetyF + `<VetaF seq_cislo="${i + 1}" kod="${invoicesData[i]?.InvoiceNo}"/>`;

    }


    const xml = `<?xml version="1.0" encoding="UTF-8"?>
        <Pisemnost xsi:noNamespaceSchemaLocation="dphzvd_epo2.xsd" verzeSW="31.18.1" nazevSW="EPO MF ČR" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">

            <DPHZVD verzePis="01.01">

                <VetaD dest_m_state="DE" 
                    souhlas="Ano" dokument="ZVD" k_uladis="DPH" 
                    uplat_koef="" konec="${invoiceFileData?.ApplicationTo}" 
                    zacatek="${invoiceFileData.ApplicationFrom}" 
                    typ_zvd="${invoiceFileData?.InvoiceFileTypeId}" 
                    celk_odp_eu="740.89" celk_odp="749.91"/>

                <VetaN nace="6201"/>
                ${''/* Client info? */}
                <VetaP prijmeni="" dodobchjm="" dic="28235550" jmeno="" 
                naz_obce="Praha (Karlín)" k_stat="CZ" psc="CZ-186 00" 
                zkrobchjm="Ataccama Software, s.r.o." typ_ds="P" ulice="Sokolovská 685/136F" titul=""/>

                <Veta2 iban="DE15692910000230377405" mena="EUR" maj_typ="representative" email_zad2="" 
                email_zad1="michalina.szymanska@cessco.eu" c_telef_zad="+420606632783" 
                maj_jmeno="United Cash Back AG" email_zast1="michalina.szymanska@cessco.eu" 
                c_telef_zast="vat@rwstb.de" bic="GENODE61RAD"/>
                
                ${vety3}
                
                ${vetyE}
                
            </DPHZVD>
        </Pisemnost>`;

    const blob = new Blob([xml], {type: 'text/xml'});
    SaveAs.saveAs(blob, "CZInvoiceFile.xml");
};

const exportRoXml = ( invoiceFileData: InvoiceFile, invoicesData: Invoice[] ) => {
    let RoInvoices = '';

    for (let i=0; i <invoicesData.length; i++) {
        RoInvoices = RoInvoices + `
            <Invoice>
                <SequenceNumber>1 ${''/*i + 1*/}</SequenceNumber>
                <simplifiedInvoice>0</simplifiedInvoice>
                <ReferenceNumber>012-240370 ${invoicesData[i]?.SupplierVATNo.slice(2)}</ReferenceNumber>
                <IssuingDate>${invoicesData[i]?.InvoiceDate}</IssuingDate>
                <GoodsDescription>
                    <GoodsItem>
                        <Code>6</Code>
                        <SubCode>6.1</SubCode>
                    </GoodsItem>
                </GoodsDescription>
                <TransactionDescription>
                    <TaxableAmount>354.20</TaxableAmount>
                    <VATAmount>70.80${''/*invoicesData[i]?.AmountOfVAT*/}</VATAmount>
                </TransactionDescription>
                <Deduction>
                    <ProRataRate/>
                    <DeductibleVATAmount>70.80${''/*invoicesData[i]?.AmountOfVAT*/}</DeductibleVATAmount>
                </Deduction>
                <EUSupplier>
                    <NameFree>${invoicesData[i]?.SupplierAddress?.Name}</NameFree>
                    <AddressFree>${invoicesData[i]?.SupplierAddress?.Street + ', ' + 
        invoicesData[i]?.SupplierAddress?.PostalCode + ', ' + invoicesData[i]?.SupplierAddress?.City}</AddressFree>
                    <CountryCode>${invoicesData[i]?.SupplierAddress?.Country}</CountryCode>
                    <Phone/>
                    <EUTraderID>
                        <VATIdentificationNumber>${invoicesData[i]?.SupplierVATNo.slice(2)}</VATIdentificationNumber>
                    </EUTraderID>
                </EUSupplier>
            </Invoice>`
    }

    let CodF1 = `
        <codF1>
            <formType>D318</formType>
            <formId>318</formId>
            <universalCode>D318_v1</universalCode>
        </codF1>`;

    let ApplicationReference = `
        <ApplicationReference>
            <RefundingCountryCode>GB ${invoiceFileData?.SalesCountry}</RefundingCountryCode>
            <Currency>GBP ${invoiceFileData?.SalesCurrency}</Currency>
            <Language>en</Language>
            <Year>2018 ${invoiceFileData?.VATYear}</Year>
            <ApplicationType>1</ApplicationType>
            <ReferenceNumber/>
            <Annual>1</Annual>
        </ApplicationReference>`;

    let RefundPeriod = `
        <RefundPeriod>
            <StartDate>1</StartDate>
            <EndDate>12</EndDate>
        </RefundPeriod>`;

    let ProrateAdjustment = `
        <ProrateAdjustment>
            <Year/>
            <FinalProrate/>
        </ProrateAdjustment>`;

    /**
     * Klient ifo?
     */
    let Applicant = `
        <Applicant>
            <NameFree>HONEYWELL LIFE SAFETY ROMANIA S.R.L.</NameFree>
            <VATIdentificationNumber>3485911</VATIdentificationNumber>
            <AddressFree>Str. Salcamilor 2BIS, Lugoj (Timis)</AddressFree>
            <Phone>0040723299088</Phone>
            <EmailAddress>bucuresti@cessco.eu</EmailAddress>
        </Applicant>`;

    let BusinessDescription = `
        <BusinessDescription>
            <BusinessActivity>2790</BusinessActivity>
        </BusinessDescription>`;

    let Representative = `
        <Representative>
            <NameFree>Cashback VAT Reclaim UK Ltd</NameFree>
            <RepresentativeID>GB945130733</RepresentativeID>
            <AddressFree>c/o Vanha Talvitie 11 C FI-00580 HELSINKI Finland</AddressFree>
            <PostCode>00580</PostCode>
            <Phone>0035894778040</Phone>
            <EmailAddress>ukvat@cashback.fi</EmailAddress>
        </Representative>`;

    let DetailedBankAccount = `
        <DetailedBankAccount>
            <Total>342.67</Total>
            <Currency>GBP</Currency>
            <OwnerType>2</OwnerType>
            <OwnerName>United Cash Back AG</OwnerName>
            <IBAN>LU552294237201400000</IBAN>
            <BIC>HANDLULB</BIC>
        </DetailedBankAccount>`;

    let PurchaseInformation = `
        <PurchaseInformation>
            ${RoInvoices}
        </PurchaseInformation>`;

    let NumberOfDocuments = `
        <NumberOfDocuments>
            <AtachatedFiles/>
            <PurchaseOrders>12</PurchaseOrders>
            <ImportOrders>0</ImportOrders>
        </NumberOfDocuments>`;

    let ApplicantSignature = `
        <ApplicantSignature>
            <NameFree>Ifrim Veronica Georgiana</NameFree>
            <Position>Economist</Position>
            <TextField1>Vers.1.0.6/ 28.01.2014</TextField1>
        </ApplicantSignature>`;

    const RoXml = `<?xml version="1.0" encoding="UTF-8"?>
        <VATRefundApplication>
            ${CodF1}
            ${ApplicationReference}
            ${RefundPeriod}
            ${ProrateAdjustment}
            ${Applicant}
            ${BusinessDescription}
            ${Representative}
            ${DetailedBankAccount}
            ${PurchaseInformation}
            ${NumberOfDocuments}
            ${ApplicantSignature}
    </VATRefundApplication>`;

    const RoBlob = new Blob([RoXml], {type: 'text/xml'});
    SaveAs.saveAs(RoBlob, "RoInvoiceFile.xml");
};

const exportSkXml = ( invoiceFileData: InvoiceFile, invoicesData: Invoice[] ) => {

    let SkInvoices = '';

    for (let i=0; i <invoicesData.length; i++) {
        SkInvoices = SkInvoices + `
            <vref:nakup>
                <vref:adresa1Dod>${invoicesData[i]?.SupplierAddress?.Street}</vref:adresa1Dod>
                <vref:adresa2Dod>${invoicesData[i]?.SupplierAddress?.PostalCode + invoicesData[i]?.SupplierAddress?.City}</vref:adresa2Dod>
                <vref:cisFak>442029863/1 ${''/*invoicesData[i]?.InvoiceNo*/}</vref:cisFak>
                <vref:datVyst>${invoicesData[i]?.InvoiceDate}</vref:datVyst>
                <vref:dphNak>77.84${''/*invoicesData[i]?.AmountOfVAT*/}</vref:dphNak>
                <vref:icDod>${invoicesData[i]?.SupplierVATNo.slice(2)}</vref:icDod>
                <vref:kodKrajNak>${invoicesData[i]?.SupplierVATNo.slice(0,2)}</vref:kodKrajNak>
                <vref:kodTovNak>6</vref:kodTovNak>
                <vref:menoDod>${invoicesData[i]?.SupplierName}</vref:menoDod>
                <vref:odpocDphNak>77.84${''/*invoicesData[i]?.AmountOfVAT*/}</vref:odpocDphNak>
                <vref:odpocPercNak/>
                <vref:porCis>0</vref:porCis>
                <vref:zaklDanNak>920.86</vref:zaklDanNak>
                <vref:menaNak>EUR ${''/*invoiceFileData?.SalesCurrency*/}</vref:menaNak>
            </vref:nakup>`
    }

    let SkXml = `<?xml version="1.0" encoding="UTF-8"?>
        <vref:vatRef xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:tax.novitech.sk:vref:request:v1" 
        xmlns:vref="urn:tax.novitech.sk:vref:request:v1">
            <vref:refPoziadavka>
                <vref:krajRef>DE</vref:krajRef>
                <vref:bicLok>GENODE61RAD</vref:bicLok>
                <vref:ibanLok>DE15692910000230377405</vref:ibanLok>
                <vref:menaLok>EUR</vref:menaLok>
                <vref:menoUctuLok>United Cash Back AG</vref:menoUctuLok>
                <vref:typUctuLok>zástupcu</vref:typUctuLok>
                ${SkInvoices}
            </vref:refPoziadavka>
        </vref:vatRef>`;

    const SkBlob = new Blob([SkXml], {type: 'text/xml'});
    SaveAs.saveAs(SkBlob, "SkInvoiceFile.xml");
};
