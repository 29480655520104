import React, {useState} from "react";
import { TextInput, useInput, useTranslate } from "react-admin";

import { useForm } from "react-final-form";

import { Check, Edit } from "@material-ui/icons";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Grid, FormHelperText } from "@material-ui/core";

import {required} from "../utils/validators";

import AddressInput from "./AddressInput";
import VatNumberInput from "./VatNumberInput";

import { Model as Supplier} from "../models/supplier"

type Props = {
    suppNameSource?: string,
    suppAddressSource?: string,
    suppVatNoSource?: string
    validation?: {vatNumber: boolean, nameInput: boolean, addressInput: boolean}
};

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        maxWidth: '100px',
        height: '2.2rem'
    },
}));

const SupplierPicker: React.FC<Props> = ({ suppAddressSource, suppVatNoSource, suppNameSource, validation }) => {

    const classes = useStyles();
    const translate = useTranslate();

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ wasTouched, setWasTouched] = useState(false);

    const form = useForm();
    const formValues = form.getState().values;

    const supplierAddressSource = suppAddressSource || 'SupplierAddress';
    const supplierVatNoSource = suppVatNoSource || 'SupplierVATNo';
    const supplierNameSource = suppNameSource || 'SupplierName';

    const onEdit = () => {
        setOpenPopup(true);
        setWasTouched(true)
    };

    const onClose = () => {
        setOpenPopup(false)
    };

    const getFieldsValues = () => {

        let vatNo;
        let name;
        let address = {};
        if (supplierVatNoSource.includes('[')) {
            let first = supplierVatNoSource.split('[')[0];
            let [index, second] = [...supplierVatNoSource.split('[')[1].split('].')];
            vatNo = formValues[first][index][second]
        } else {
            vatNo = formValues[supplierVatNoSource]
        }
        if (supplierNameSource.includes('[')) {
            let first = supplierNameSource.split('[')[0];
            let [index, second] = [...supplierNameSource.split('[')[1].split('].')];
            name = formValues[first][index][second]
        } else {
            name = formValues[supplierNameSource]
        }

        if (supplierAddressSource.includes('[')) {
            for (let value of ['Name', 'Street', 'City', 'PostalCode', 'Country']) {
                let first = supplierAddressSource.split('[')[0];
                let [index, second] = [...supplierAddressSource.split('[')[1].split('].')];
                // @ts-ignore
                address[value] = formValues[first][index][second]?.[value]
            }
        } else {
            for (let value of ['Name', 'Street', 'City', 'PostalCode', 'Country']) {
                // @ts-ignore
                address[value] = formValues[supplierAddressSource]?.[value]
            }
        }
        return [vatNo, name, address]
    };

    const [vatNumberValue, nameValue, addressValue] = getFieldsValues();

    const helperTextInfos = [nameValue, vatNumberValue,
        addressValue?.Name, addressValue?.Street, addressValue?.City,
        addressValue?.PostalCode, addressValue?.Country,];

    const onSupplierSelected = (supplier: Supplier | null) => {
        if (supplier) {
            form.change(supplierNameSource, supplier.Name);
            form.change(supplierAddressSource, supplier.Address);
            form.change(supplierVatNoSource, supplier.VATNo);
        } else {
            form.change(supplierNameSource, undefined);
            form.change(supplierAddressSource, undefined);
        }
    };

    const validateSupplierPicker = () => {
        const error = {
            error: false,
            message: 'Please make sure you have entered: ',
            missing: []
        };

        if (validation && wasTouched) {
            if (validation.vatNumber) {
                if (!vatNumberValue) {
                    error.error = true;
                    // @ts-ignore
                    error.missing.push('Suppliers VAT number')
                }
            }
            if (validation.nameInput) {
                if (!nameValue) {
                    error.error = true;
                    // @ts-ignore
                    error.missing.push('Suppliers name')
                }
            }
            if (validation.addressInput) {
                let addressMissing = [];
                for (let value of ['Name', 'Street', 'City', 'PostalCode', 'Country']) {
                    if (!addressValue?.[value]) {
                        error.error = true;
                        addressMissing.push(value);
                    }
                }
                if (addressMissing.length) {
                    // @ts-ignore
                    error.missing.push('Supplier address', ...addressMissing)
                }
            }
        }
        return error
    };

    const helperTextInfo = {
        error: validateSupplierPicker().error,
        text: validateSupplierPicker().error ?
            validateSupplierPicker().message + validateSupplierPicker().missing.join(', '):
            helperTextInfos.filter(val => val !== undefined && val !== null).join('; ')
    };

    const getInputProps = (inputName: string, validation: boolean) => {
        return {
            source: inputName,
            name: inputName,
            validate: validation ? required: undefined,
        }};

    useInput({...getInputProps(supplierVatNoSource, !!validation?.vatNumber)});
    useInput({...getInputProps(supplierNameSource, !!validation?.nameInput)});
    useInput({...getInputProps(supplierAddressSource, !!validation?.addressInput)});

    return (
        <>
            <h4 style={{color: '#bdbdbd', fontWeight: 400, margin: 0, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>Supplier</h4>
            <Grid container direction={'row'}>
                <FormHelperText error={helperTextInfo.error} style={{maxLines: 1, maxWidth: '200px'}}>{helperTextInfo.text}</FormHelperText>
                <Button onClick={onEdit} variant={'contained'} startIcon={<Edit/>} color={'secondary'} className={classes.button}>
                    {wasTouched ? translate('ra.action.edit'): translate('cvr.action.fill')}
                </Button>
            </Grid>
            {!wasTouched && <Grid container direction="row">
                <FormHelperText style={{ maxLines: 1, maxWidth: '200px', color: "red" }}>
                    {translate("ra.validation.required")}
                </FormHelperText>
            </Grid>}
            <Dialog open={openPopup} onClose={onClose}>
                <DialogTitle>{translate("resources.Supplier.name", 1)}</DialogTitle>
                <DialogContent>
                    <VatNumberInput onSupplierSelected={(supplier) => onSupplierSelected(supplier)}
                                    source={supplierVatNoSource} label={'Supplier VAT number'} isRequired={validation?.vatNumber}/>
                    <TextInput source={supplierNameSource} label={'Supplier Name'} validate={required()}/>
                    <AddressInput source={supplierAddressSource} label={'Supplier Address'} isRequired={validation?.addressInput}/>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"}
                            endIcon={<Check/>}
                            color={'secondary'}
                            className={classes.button}
                            onClick={onClose}
                            style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        {translate('cvr.action.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default SupplierPicker;
