import React from 'react';
import { 
    TextField, Show, ReferenceField, DateField, TopToolbar,
    EditButton, TabbedShowLayout, Tab, NumberField
} from 'react-admin';

import EmbeddedList, { useTabbedStyle } from "../../components/EmbeddedList";
import CreateInvoicePopup from "../invoice/CreatePopup";
import ExportXmlFile from "../../components/exportXmlFile/ExportXmlFile";
import UploadInvoiceFile from "../../components/uploadInvoiceFileWizard/UploadInvoiceFile";


export default (props: any) =>
{
    const classes = useTabbedStyle();
    return <Show actions={<InvoiceFileShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="Id" />
                <TextField source="Code" />
                <ReferenceField label={'Client'} source="ClientId" reference="Client">
                    <TextField source="Name" />
                </ReferenceField>
                <ReferenceField source="SalesCountry" reference="Country" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="SalesCurrency" reference="Currency" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="SubmitCountry" reference="Country" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="SubmitCurrency" reference="Currency" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'Type'} source="InvoiceFileTypeId" reference="InvoiceFileType" link={false}>
                    <TextField source="Name" />
                </ReferenceField>
                <ReferenceField label={'Status'} source="InvoiceFileStateId" reference="InvoiceFileState" link={false}>
                    <TextField source="Name" />
                </ReferenceField>
                <TextField source="VATYear" />
                <DateField source="ApplicationFrom" />
                <DateField source="ApplicationTo" />
                <TextField source="Note" />
            </Tab>
            <Tab label="Invoices" path="invoices">
                <EmbeddedList className={classes.list} reference="Invoice" createFilter={record => ({ InvoiceFileId: record?.Id })}>
                    <TextField source="Id" />
                    <TextField source="SupplierName" />
                    <TextField source="NatureOfGoods" />
                    <ReferenceField source="InvoiceFileId" reference="InvoiceFile">
                        <TextField source="Code" />
                    </ReferenceField>
                    <NumberField source="AmountOfVAT" />
                    <NumberField source="Deductable" />
                    <NumberField source="RefundApplied" />
                    <NumberField source="ProRata" />
                </EmbeddedList>
            </Tab>
        </TabbedShowLayout>
    </Show>
};

const InvoiceFileShowActions = ({ basePath, data, resource }: any) => {

    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            <CreateInvoicePopup invoiceFileId={data?.Id} />
            <ExportXmlFile data={data}/>
            <UploadInvoiceFile />
        </TopToolbar>
    )
};
