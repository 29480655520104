import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    AutocompleteInput,
    Toolbar,
    SaveButton,
    useDataProvider,
} from 'react-admin';
import { email } from "../../utils/validators";
import AddressInput from '../../components/AddressInput';
import VatNumberInput from "../../components/VatNumberInput";

const EditToolbar = ({permissions, ...props}: any) => {
    const { hasList, hasEdit, hasShow, hasCreate, ...rest } = props;
    return (
        <Toolbar {...rest}>
            <SaveButton />
        </Toolbar>
    )
};

const LeadEdit = ({ permissions, ...props }: any) => {
    const [countries, setCountries] = React.useState<any[]>([]);
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        let mounted = true;
        const getData = async () => {
            const data = await dataProvider.getList('Country', {
                filter: {},
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'id', order: 'ASC' }
            });
            if (mounted) setCountries(data.data);
        };

        getData();
        return () => { mounted = false };
    }, [dataProvider]);

    return <Edit {...props} permissions={permissions}>
        <SimpleForm toolbar={<EditToolbar {...props} permissions={permissions} />}>
            <TextInput source="Id" disabled />
            <TextInput source="Name" />
            <TextInput source="Code" disabled />
            <TextInput source="Phone" />
            <TextInput source="Email" validate={email()} />
            <TextInput source="Website" />
            <AutocompleteInput
                source="SalesCountry"
                choices={countries}
                optionText={(c: any) => c ? c?.name + ' (' + c?.id + ')' : ''}
                suggestionLimit={1000}
            />
            <VatNumberInput source="VATNo" label={'VAT number'} />
            <AddressInput source="PostalAddress" />
            <AddressInput source="VisitingAddress" />
            <TextInput source="AdditionalNotes" />
        </SimpleForm>
    </Edit>;
};

export default LeadEdit;
