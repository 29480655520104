import React, { useState } from 'react';
import { Confirm, Button } from 'react-admin';

type Props =
{
    title: string,
    content: string,
    action: () => Promise<void>,
    type: 'button' | 'icon',
    onSuccess?: () => Promise<void>,
    icon?: JSX.Element
};

export default ({ title, content, action = async () => { }, onSuccess = async () => { }, icon , type}: Props) =>
{
    const [ open, setOpen ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);

    const onDelete = () => setOpen(true);
    const onClose = () => setOpen(false);
    const onConfirm = async () =>
    {
        setDeleting(true);
        await action()
            .finally(() => {
                setDeleting(false);
                setOpen(false);
                onSuccess();
            });
    };

    return <>
        {type === "icon" ?
            <div onClick={onDelete} style={{cursor: "pointer", display: "grid", justifyContent: "center"}}>{icon}</div> :
            <Button onClick={onDelete} label={title}>{icon}</Button>}
        <Confirm
            isOpen={open}
            loading={deleting}
            title={title}
            content={content}
            onConfirm={onConfirm}
            onClose={onClose}
        />
    </>;
};
