import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishRA from "ra-language-english";
import czechRA from "ra-language-czech";

const englishCVR = require('../langs/en.json');
const czechCVR = require('../langs/cs.json');

type Langs = "en" | "cs";

const getLangSetting = (): Langs => {
    return localStorage.getItem("lang") === "cs"
        ? "cs"
        : "en";
}

const messages: {[locale in string]: any} =
{
    en: { ...englishRA, ...englishCVR },
    cs: { ...czechRA, ...czechCVR },
};

export default polyglotI18nProvider(locale => messages[locale], getLangSetting());
