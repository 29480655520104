import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';

const CurrencyFilter = (props: any) => <Filter {...props}>
    <TextInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
</Filter>;

export default (props: any) => <List {...props} filters={<CurrencyFilter />} exporter={false}>
    <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
    </Datagrid>
</List>;
