import omit from 'lodash/omit';
import { ResourceType, getRelationFields } from '../models'
import { Create, Get, List, Update, Delete } from '../services/DataService';
import { getJsonProvider } from './JsonProvider';

export type RequestType = "GET_LIST" | "GET_ONE" | "GET_MANY" | "GET_MANY_REFERENCE" |
    "CREATE" | "UPDATE" | "UPDATE_MANY" | "DELETE" | "DELETE_MANY";

export type Data = any;

export type ListFilter = {
    [field: string]: string | number | { ne?: any[] }
}

export type Params<T extends RequestType> =
    T extends "GET_ONE" ? { id: string } :
    T extends "CREATE" ? { data: Data } :
    T extends "DELETE" ? { id: string, previousData: Data } :
    T extends "GET_MANY" | "DELETE_MANY" ? { ids: string[] } :
    T extends "GET_MANY_REFERENCE" ? { } :
    T extends "UPDATE_MANY" ? { } :
    T extends "UPDATE" ? {
        id: string,
        previousData: Data,
        data: Data,
    } :
    T extends "GET_LIST" ? {
        filter: ListFilter,
        sort: {
            field: string,
            order: "ASC" | "DESC",
        },
        pagination: {
            page: number,
            perPage: number,
        },
    } :
    never;

/*
const API_URL = "http://localhost:3001/DataService.svc";

const updateRelations = async (resource: ResourceType, id: string, rels: any) =>
{
    // TODO implement

    if (!rels) return;
    var data: any[] = [];
    for (let relation in rels)
    {
        const resurl = `${API_URL}/${resource}(${id})/${rels[relation].reference}s`;
        fetchJson(resurl, {
            method: 'GET',
        })
        .then(json => {
            // Načte relation data
            for (var resValue in json.value)
            {
                data.push(json.value[resValue].Id);
            }
            for (let id in rels[relation].ids)
            {
                // Zkontrolování zda existuje záznam, pokud ano nevytváříme nový, ale smažeme ho z data, vše co zbyde v data pak smažeme
                if (data.includes(rels[relation].ids[id]))
                {
                    // Najde index daného záznamu v poli
                    var index = data.indexOf(rels[relation].ids[id]);
                    // Smaže záznam z pole data
                    if (index > -1)
                    {
                        data.splice(index, 1);
                    }
                    continue;
                }
                // dotaz na vytvoření relation recordu dané resource
                var relationUrl = `${API_URL}/${resource}(${id})/$links/${rels[relation].reference}s`
                var body = { "url": `${API_URL}/${rels[relation].reference}(${rels[relation].ids[id]})`};
                fetchJson(relationUrl, {
                  method: "POST",
                  body: JSON.stringify(body),
                })
            }
            // Smaže všechny záznamy, které byly odebrány
            if (typeof data !== 'undefined' && data.length > 0)
            {
                for(let recordId in data)
                {
                    var url = `${API_URL}/${resource}(${id})/$links/${rels[relation].reference}s(${data[recordId]})`;
                    fetchUtils.fetchJson(url, {
                        method: "DELETE",
                    })
                }
            }
        })
    }
}
*/

const getList = async (resource: ResourceType, params: Params<"GET_LIST">) =>
{
    const convertFilterParams = (filter: ListFilter) =>
    {
        return Object.entries(filter).map(([name, value]) =>
        {
            switch (typeof value)
            {
                case "string":
                    return `substringof('${value}', ${name}) eq true`;
                case "object":
                    // Works if filter object is {[attr]: {ne: [values]}} Meant for filtering by ids with not equal list
                    return (value?.ne || [])
                        .map(id => `${name} ne ${id}`)
                        .join(' and ');
                default:
                    return `${name} eq ${value}`;
            }
        }).filter(str => !!str).join(" and ");
    };

    const { pagination: { page, perPage }, sort: { field, order }, filter } = params;

    const query = {
        $inlinecount: "allpages",
        $orderby: `${field === "id" ? "Id" : field} ${order.toLowerCase()}`,
        $filter: convertFilterParams(filter),
        $top: perPage,
        $skip: (page - 1) * perPage
    };

    const json = await List(resource, query);
    const { value } = json;

    console.log("GET_LIST", resource, value);

    return {
        data: (Array.isArray(value) ? value : []).map(obj => ({ id: obj.Id, ...obj })),
        total: parseInt(json["odata.count"]) || 0,
    };
}

const getOne = async (resource: ResourceType, params: Params<"GET_ONE">) =>
{
    const { id } = params;

    const expand = getRelationFields(resource);
    const json = await Get(resource, id, { expand });

    console.log("GET_ONE", resource, json);

    return {
        data: { id: json.Id, ...json },
    };
}

const getMany = async (resource: ResourceType, params: Params<"GET_MANY">) =>
{
    const query = {
        $filter: params.ids
            .map(id => `Id eq ${id}`)
            .join(" or ")
    };

    const json = await List(resource, query);
    const { value } = json;

    console.log("GET_MANY", resource, value);

    return {
        data: (Array.isArray(value) ? value : []).map(obj => ({ id: obj.Id, ...obj })),
    };
}

const getManyReference = async (resource: ResourceType, params: Params<"GET_MANY_REFERENCE">) =>
{
    throw new Error('cvr.error.unsupportedAction');
}

const create = async (resource: ResourceType, params: Params<"CREATE">) =>
{
    const rels = getRelationFields(resource);
    const data = omit(params.data, rels || []);

    const json = await Create(resource, data);

    /*
    const callLinksApiRequests = (resource: ResourceType, id: string, rels: any) =>
    {
        for (let rel of rels)
        {
            for (let relId of rel.ids)
            {
                const url = `${API_URL}/${resource}(${id})/$links/${rel.reference}s`;
                const body = { "url": `${API_URL}/${rel.reference}(${relId})`};
                
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(body),
                })
                .then((response: any) => ({
                    data: response.json,
                }));
            }
        }
    }

    if (rels) callLinksApiRequests(resource, json.Id, rels);
    */

    return {
        data: { id: json.Id, ...json },
    };
}

const update = async (resource: ResourceType, params: Params<"UPDATE">) =>
{
    const { id } = params;
    const rels = getRelationFields(resource);
    const data = omit(params.data, [ "Id", "id", "odata", "odata.metadata", ...rels || []]);

    await Update(resource, id, data);

    //if (rels) updateRelations(resource, id, rels);
    return { data: { id }}
}

const updateMany = async (resource: ResourceType, params: Params<"UPDATE_MANY">) =>
{
    throw new Error('cvr.error.unsupportedAction');
}

const deleteOne = async (resource: ResourceType, params: Params<"DELETE">) =>
{
    const { id } = params;

    await Delete(resource, id);

    return { 
        data: params.previousData
    };
}

const deleteMany = async (resource: ResourceType, params: Params<"DELETE_MANY">) =>
{
    // TODO more effective implementation

    return Promise.all(
        params.ids.map(id => deleteOne(resource, { id, previousData: undefined }))
    ).then(() => {
        return({data: params.ids})
    });
}

const isJsonResource = (resource: ResourceType) => resource === "Country" || resource === "Currency" || resource === "Country_Currency";

const getListSelector = async (resource: ResourceType, params: Params<"GET_LIST">) =>
{
    return isJsonResource(resource)
        ? getJsonProvider().then(provider => provider.getList(resource, params))
        : getList(resource, params);
}

const getManySelector = async (resource: ResourceType, params: Params<"GET_MANY">) =>
{
    return isJsonResource(resource)
        ? getJsonProvider().then(provider => provider.getMany(resource, params))
        : getMany(resource, params);
}

const getOneSelector = async (resource: ResourceType, params: Params<"GET_ONE">) =>
{
    return isJsonResource(resource)
        ? getJsonProvider().then(provider => provider.getOne(resource, params))
        : getOne(resource, params);
}

export default
{
    getList: getListSelector,
    getOne: getOneSelector,        
    getMany: getManySelector,
    getManyReference,
    create,
    update,
    updateMany,
    delete: deleteOne,
    deleteMany,
}
